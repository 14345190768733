import React, { useEffect, useState } from "react";
import { First } from "./Common/First";
import { Five } from "./Common/Five";
import { Four } from "./Common/Four";
import { Second } from "./Common/Second";
import { Third } from "./Common/Third";
import { Six } from "./Common/Six";
import useScript from "./useScript";
import psgecommerce from "./../images/pages/psg_ecommerce.webp";
import psgecommerceuk from "./../images/pages/psg_ecommerce_UK.webp";
import psgecommercewebsiteuk from "./../images/psg_ecommerce_website_UK.webp";
import "./../css/style.css";
import "./../css/inner.css";
import { Backbutton } from "./Common/Backbutton";
export const EcommersePSG = () => {
  useScript("./assets/js/scripts.js");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showBtn, setShowBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);
  return (
    <>
      {showBtn && <Backbutton />}
      <div id="page" data-barba="container">
        <div id="content" className="page-content">
          <First
            head1="PSG grant"
            head2="for Ecommerce site."
            para="An eCommerce company can only achieve its goal after its customers achieve theirs. A website offering visitors a nice and easy 'cart to checkout' experience does half the job, and the PSG (70% grant) is there to support your business in London,London, UK. For the rest, you want to have an eCommerce web design company that knows the art of crafting a customer-centric user interface."
            img={psgecommerce}
          />
          <Second
            secondheading1="Don't miss the opportunity, grab 70% PSG grant"
            secondheading2="And start your online eCommerce website today"
            img={psgecommerceuk}
          />
          <Third
            head4="Since 2018, the London,London, UK Government has been offering support to local small and medium enterprises through Productivity Solutions Grant (PSG) that is meant to help them improve operations and productivity with the utilisation of IT equipment and services. Did you just qualify for PSG (70% grant) and cannot wait further to start your eCommerce website? Tiger It is ready to help you with everything from development to maintenance for PSG."
            p1="Throughout all the years we've been working in London,London, UK, we've identified six most important aspects of eCommerce businesses which include solutions for customer management, financial transactions, inventory tracking, data analytics, maintenance tweaks, and security implementations. Our expertise in designing and developing eCommerce solutions spans across all areas of eCommerce businesses to bring your customers, regardless of their location in London,London, UK, a seamless buying experience."
            p2="An eCommerce website that cannot draw your customers back to the store is not a good site at all. Being the owner of a business in London,London, UK, you'll surely try to make the most of your PSG (70% grant). But you still need a web design agency in London,London, UK that can take all the hassles, from designing your website, handling required integrations, setting up the store and payment methods, developing solutions (if required), launching the site, and fulfilling the maintenance requirements on an ongoing basis. If that sounds like you, you are exactly where you should be to get your PSG project handled by people with the right set of skills."
            linkname="Start A Project Now"
          />
          <Four img={psgecommercewebsiteuk} />
          <Five
            h1="Are you looking for a Ecommerce web development agency that offers up to 70% PSG? Tiger It will be the best choice for you."
            p1="A sure thing about our agency in London,London, UK is that we don't just design an eCommerce website and ask you to pay. We don't talk our eCommerce clients in London,London, UK into choosing a predesigned template that may lack the originality and exclusivity your business model comprises. Our web design and development professionals follow the harder yet more appropriate way. Not only can our designers help you get a freshly designed eCommerce website, our developers in London,London, UK can boost its usability by integrating sector-specific tools and functions to make the PSG project a success."
            p2="Maintenance is the key to keeping an eCommerce website in service to customers, which is an obvious goal of any company in London,London, UK. With this constant demand in mind, our web designers and developers in London,London, UK have a well-thought work process to serve PSG clients as long as they need even after launching their eCommerce websites. Once you have an agency design your website; it's only the beginning of a bigger journey where responsibilities are many, and our agency is always ready to cooperate with you."
            p3="Besides PSG website maintenance, updates to the entire system (eCommerce module) are important. Our decade-long experience in the business sector of London,London, UK has brought us the confidence and strength to design and develop any custom eCommerce module that passes even the most stringent penetration and security assessment. You may include any custom module as part of the PSG project or a maintenance deal. Professional designers and developers at our agency in London,London, UK are able to deliver excellence either way."
            linkname="Start A Project Now"
          />
          <Six link1="Start a Project" link2="Request for a Quotation" />
        </div>
      </div>
    </>
  );
};
