import React from "react";

export const Loader = () => {
  return (
    <div data-duration="3" class="alioth-page-loader" data-layout="dark">
      <span class="apl-background"></span>
      <div class="apl-count"></div>
    </div>
  );
};
