import React from "react";
import { Link } from "react-router-dom";

export const Six = (props) => {
  const pagerefresh = () => {
    window.location.pagerefresh();
  };

  return (
    <div className="section">
      <div className="wrapper-full no-margin indexing">
        <div className="c-col-12 no-gap no-margin">
          <div className="alioth-page-nav">
            <Link to="/contact-us" onClick={pagerefresh}>
              <div className="page-title">{props.link1}</div>
              <div className="page-sub-title">{props.link2}</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
