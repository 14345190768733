import React, { useEffect, useState } from "react";
import { First } from "../Common/First";
import { Five } from "../Common/Five";
import { Four } from "../Common/Four";
import { Second } from "../Common/Second";
import { Six } from "../Common/Six";
import { Third } from "../Common/Third";
import "./../../css/style.css";
import "./../../css/inner.css";
import "./../../css/plugins.css";
import useScript from "./../useScript";
import ecommercedevelopment from "./../../images/pages/ecommerce_development.webp";
import ecommercedevelopmentuk from "./../../images/pages/ecommerce_development_UK.webp";
import ecommerceuk from "./../../images/ecommerce_UK.webp";
import { Backbutton } from "../Common/Backbutton";
export const EcommerceWebDevelopment = () => {
  useScript("./assets/js/scripts.js");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showBtn, setShowBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);
  return (
    <>
      {showBtn && <Backbutton />}
      <div id="page" data-barba="container">
        <div id="content" className="page-content">
          <First
            head1="Ecommerce"
            head2="web development."
            para="Trying to find the perfect eCommerce web development company to complete your next eCommerce website in London, UK? From custom eCommerce stores and online marketing to bespoke websites and scalable web design and development, we offer affordable, scalable, and reliable eCommerce web development services for companies that want to strengthen their online presence in London, UK."
            img={ecommercedevelopment}
          />
          <Second
            secondheading1="An eCommerce website means nothing to your company"
            secondheading2="Unless it makes customers feel they can buy from you"
            img={ecommercedevelopmentuk}
          />
          <Third
            head4="With London, UK's booming eCommerce industry, every web design and development company that specialises in e-commerce works to design a website that acts as a point of contact between the brand and customers. Additionally, many of London, UK companies are drawn to the tremendous scalability, which we believe is an integral characteristic of an e-commerce website. As a eCommerce web development company, we too have changed the way we serve our customers in London, UK."
            p1="Our ability to work with several eCommerce platforms in London, UK means we can tailor a plan for your particular needs. As an eCommerce shop expert in London, UK, we will ensure your online store is designed to display your product range and target market and ensure you have the functionality necessary to manage your store effectively and bring the desired results for your company."
            p2="The principle that no matter how much money a client spends in London, UK, our eCommerce website designers and developers deliver value to them is one of the principles we grew up with as a web development company. So, if you work with our team of e-commerce developers, you won't spend more and get less."
            linkname="Start A Project Now"
          />

          <Four img={ecommerceuk} />

          <Five
            h1="Are you looking for a Ecommerce web development company in London, UK? We develop productive websites that your business needs."
            p1="The job of a designer is equally as important as that of the developer when it comes to building an eCommerce website. Whenever our expert designers and developers build eCommerce sites in London, UK, they make sure clients get the return on the investment they are looking for. As part of our e-commerce development services, we make sure that our clients see a positive return on investment. So, we stay focused on the collaboration between these two groups of professionals."
            p2="As an eCommerce web design and development agency in London, UK, we grew up with certain principles: the delivery of value to clients, no matter how much they spend. So, with our eCommerce developers by your side, you won't pay too much for too little. Of course, we recognise every business has different requirements and can tailor our service to match your needs, whether this is a simple website redesign or complete eCommerce services."
            p3="Website development is as much the responsibility of designers as it is of developers. As part of our eCommerce website development services, we make sure that our clients see a positive return on investment. We focus on collaborating with the clients to fulfill their unique needs that come with many challenges. Our eCommerce website developers and designers are dedicated to delivering results to our clients in London, UK with our designers and eCommerce experts. How about allowing us to help you?"
            linkname="Start A Project Now"
          />
          <Six link1="Start a Project" link2="Request for a Quotation" />
        </div>
      </div>
    </>
  );
};
