import React from "react";
import { UserReview } from "./UserReview";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";

import "../../../css/testimonails.css";

import image1 from "../../../images/testimonials/picture-1.webp";
import image2 from "../../../images/testimonials/picture-2.webp";
import image3 from "../../../images/testimonials/picture-3.webp";

export const Testimonials = () => {
  return (
    <div className="testimonials">
      <h1 className="testimonials-header">Hear from Our Happy Customers</h1>
      <Carousel
        className="testimonials-carousel"
        showArrows={true}
        showIndicators={true}
        infiniteLoop={true}
        showStatus={false}
        autoPlay={true}
        interval={3000}
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          const defStyle = {
            marginLeft: 10,
            backgroundColor: "white",
            width: "10px",
            height: "10px",
            cursor: "pointer",
            borderRadius: "50%",
            display: "inline-block",
          };
          const style = isSelected
            ? { ...defStyle, backgroundColor: "#0A0D10" }
            : { ...defStyle };
          return (
            <span
              style={style}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              aria-label={`${label} ${index + 1}`}
            ></span>
          );
        }}
        renderArrowPrev={(clickHandler, hasPrev) => {
          return (
            <div className="icon-ctn-left circle" onClick={clickHandler}>
              <FaChevronLeft className="icon" />
            </div>
          );
        }}
        renderArrowNext={(clickHandler, hasPrev) => {
          return (
            <div className="icon-ctn-right circle" onClick={clickHandler}>
              <FaChevronRight className="icon" />
            </div>
          );
        }}
      >
        <UserReview image={image1} />
        <UserReview image={image2} />
        <UserReview image={image3} />
      </Carousel>
    </div>
  );
};
