import React, { useEffect, useState } from "react";
import one from "./../images/projects/flickontechnology.webp";
import two from "./../images/projects/BBQMOMMENT.webp";
import three from "./../images/projects/QOLLORETTE.webp";
import four from "./../images/projects/MAXBOND.webp";
import five from "./../images/projects/WINAWIN.webp";
import six from "./../images/projects/MOTTIWAY.webp";
import seven from "./../images/projects/7.webp";
import eight from "./../images/projects/tavo_pokycio_gidas.webp";
import nine from "./../images/projects/SOMORE.webp";
import ten from "./../images/projects/EXITMAKERS.webp";
import eleven from "./../images/projects/SHOPAY.webp";
import twelve from "./../images/projects/OCTALOOP.webp";
import "./../css/plugins.css";
import "./../css/style.css";
import "./../css/inner.css";
import "./../css/portfolio.css";
import useScript from "./useScript";
import { Backbutton } from "./Common/Backbutton";
export const PortFolio = () => {
  useScript("./assets/js/scripts.js");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showBtn, setShowBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);
  return (
    <>
      {showBtn && <Backbutton />}
      <div id="page" data-barba="container">
        <div id="content" className="page-content indexing">
          <div className="section no-margin">
            <div className="wrapper-full no-gap no-margin">
              <div className="c-col-12 no-gap">
                <div
                  className="portfolio-showcase showcase-slideshow-v2"
                  data-barba-namespace="sc-slideshow"
                >
                  <span className="ss2-overlay"></span>
                  <div className="ss2-back-texts"></div>
                  <div className="showcase-slideshow-2-wrapper">
                    <div className="ss2-project">
                      <div className="ss2-project-meta">
                        <div className="ss2-project-cat">
                          Website Design & Development
                        </div>
                        <div className="ss2-project-title">
                          Flickon Technology
                        </div>
                        <div className="ss2-project-excerpt">
                          This is a web design project which was designed and
                          developed for our honorable client in London, UK.
                        </div>
                      </div>
                      <div className="ss2-project-image">
                        <img alt="Project Image" src={one} />
                      </div>
                      <a
                        className="ss2-project-url"
                        href="https://flickontechnology.com/"
                      ></a>
                    </div>
                    <div className="ss2-project">
                      <div className="ss2-project-meta">
                        <div className="ss2-project-cat">
                          Website Design & Development
                        </div>
                        <div className="ss2-project-title">BBQ Momment</div>
                        <div className="ss2-project-excerpt">
                          This is a web design project which was designed and
                          developed for our honorable client in London, UK.
                        </div>
                      </div>
                      <div className="ss2-project-image">
                        <img alt="Project Image" src={two} />
                      </div>
                      <a
                        className="ss2-project-url"
                        href="https://www.bbqmoments.com/"
                      ></a>
                    </div>

                    <div className="ss2-project">
                      <div className="ss2-project-meta">
                        <div className="ss2-project-cat">
                          Website Design & Development
                        </div>
                        <div className="ss2-project-title">Qollorette</div>
                        <div className="ss2-project-excerpt">
                          This is an ecommerce web design project which was
                          designed and developed for our honorable client in
                          London, UK.
                        </div>
                      </div>
                      <div className="ss2-project-image">
                        <img alt="Project Image" src={three} />
                      </div>
                      <a
                        className="ss2-project-url"
                        href="https://www.qollorette.com/"
                      ></a>
                    </div>

                    <div className="ss2-project">
                      <div className="ss2-project-meta">
                        <div className="ss2-project-cat">
                          Website Design & Development
                        </div>
                        <div className="ss2-project-title">Maxbond</div>
                        <div className="ss2-project-excerpt">
                          This is a web design project which was designed and
                          developed for our honorable client in London, UK.
                        </div>
                      </div>
                      <div className="ss2-project-image">
                        <img alt="Project Image" src={four} />
                      </div>
                      <a
                        className="ss2-project-url"
                        href="http://maxbond.in/"
                      ></a>
                    </div>

                    <div className="ss2-project">
                      <div className="ss2-project-meta">
                        <div className="ss2-project-cat">
                          Website Design & Development
                        </div>
                        <div className="ss2-project-title">Winawin</div>
                        <div className="ss2-project-excerpt">
                          This is an ecommerce web design project which was
                          designed and developed for our honorable client in
                          London, UK.
                        </div>
                      </div>
                      <div className="ss2-project-image">
                        <img alt="Project Image" src={five} />
                      </div>
                      <a
                        className="ss2-project-url"
                        href="https://app.winawin.net/"
                      ></a>
                    </div>
                    <div className="ss2-project">
                      <div className="ss2-project-meta">
                        <div className="ss2-project-cat">
                          Website Design & Development
                        </div>
                        <div className="ss2-project-title">Mottiway</div>
                        <div className="ss2-project-excerpt">
                          This is a web design project which was designed and
                          developed for our honorable client in London, UK.
                        </div>
                      </div>
                      <div className="ss2-project-image">
                        <img alt="Project Image" src={six} />
                      </div>
                      <a
                        className="ss2-project-url"
                        href="https://www.mottiway.com/"
                      ></a>
                    </div>
                    <div className="ss2-project">
                      <div className="ss2-project-meta">
                        <div className="ss2-project-cat">
                          Website Design & Development
                        </div>
                        <div className="ss2-project-title">
                          Skaitmeninis Verslas
                        </div>
                        <div className="ss2-project-excerpt">
                          This is a web design project which was designed and
                          developed for our honorable client in London, UK.
                        </div>
                      </div>
                      <div className="ss2-project-image">
                        <img alt="Project Image" src={seven} />
                      </div>
                      <a
                        className="ss2-project-url"
                        href="https://skaitmeninisverslas.lt/"
                      ></a>
                    </div>

                    <div className="ss2-project">
                      <div className="ss2-project-meta">
                        <div className="ss2-project-cat">
                          Website Design & Development
                        </div>
                        <div className="ss2-project-title">
                          Tavo pokyciu gidas
                        </div>
                        <div className="ss2-project-excerpt">
                          This is a web design project which was designed and
                          developed for our honorable client in London, UK.
                        </div>
                      </div>
                      <div className="ss2-project-image">
                        <img alt="Project Image" src={eight} />
                      </div>
                      <a
                        className="ss2-project-url"
                        href="http://tavopokyciogidas.flickontechnology.com/"
                      ></a>
                    </div>
                    <div className="ss2-project">
                      <div className="ss2-project-meta">
                        <div className="ss2-project-cat">
                          Website Design & Development
                        </div>
                        <div className="ss2-project-title">Somore</div>
                        <div className="ss2-project-excerpt">
                          This is a web design project which was designed and
                          developed for our honorable client in London, UK.
                        </div>
                      </div>
                      <div className="ss2-project-image">
                        <img alt="Project Image" src={nine} />
                      </div>
                      <a
                        className="ss2-project-url"
                        href="https://www.somore.lt/"
                      ></a>
                    </div>
                    <div className="ss2-project">
                      <div className="ss2-project-meta">
                        <div className="ss2-project-cat">
                          Website Design & Development
                        </div>
                        <div className="ss2-project-title">Exit Makers</div>
                        <div className="ss2-project-excerpt">
                          This is a web design project which was designed and
                          developed for our honorable client in London, UK.
                        </div>
                      </div>
                      <div className="ss2-project-image">
                        <img alt="Project Image" src={ten} />
                      </div>
                      <a
                        className="ss2-project-url"
                        href="https://www.exitmakers.com/"
                      ></a>
                    </div>
                    <div className="ss2-project">
                      <div className="ss2-project-meta">
                        <div className="ss2-project-cat">
                          Website Design & Development
                        </div>
                        <div className="ss2-project-title">Shopay</div>
                        <div className="ss2-project-excerpt">
                          This is a web design project which was designed and
                          developed for our honorable client in London, UK.
                        </div>
                      </div>
                      <div className="ss2-project-image">
                        <img alt="Project Image" src={eleven} />
                      </div>
                      <a
                        className="ss2-project-url"
                        href="http://shopay.flickontechnology.com/"
                      ></a>
                    </div>
                    <div className="ss2-project">
                      <div className="ss2-project-meta">
                        <div className="ss2-project-cat">
                          Website Design & Development
                        </div>
                        <div className="ss2-project-title">Octloop</div>
                        <div className="ss2-project-excerpt">
                          This is a web design project which was designed and
                          developed for our honorable client in London, UK.
                        </div>
                      </div>
                      <div className="ss2-project-image">
                        <img alt="Project Image" src={twelve} />
                      </div>
                      <a
                        className="ss2-project-url"
                        href="https://octaloop.com/"
                      ></a>
                    </div>
                  </div>
                  <div className="ss2-button">
                    <a href="#">Visit Website</a>
                  </div>
                  <div className="ss2-images swiper-container"></div>
                  <div className="ss2-dots"></div>
                  <div className="ss2-nav">
                    <div className="ss2-prev">
                      <i className="icofont-thin-left"></i>
                    </div>
                    <div className="ss2-fract">
                      <div className="ss2-curr"></div>
                      <div className="ss2-tot"></div>
                    </div>
                    <div className="ss2-next">
                      <i className="icofont-thin-right"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
