import React, { useEffect, useState } from "react";
import { Footer } from "../Footer";
import { Header } from "../Header";
import ukneedswebsitecover from "./../../images/blog_img/UK_needs_website_cover.webp";
import ukneedswebsitebody from "./../../images/blog_img/UK_needs_website_body.webp";
import { Link } from "react-router-dom";
import "./../../css/style.css";
import "./../../css/inner.css";
import "./../../css/plugins.css";
import useScript from "../useScript";
import { Backbutton } from "../Common/Backbutton";
export const TermsandConditions = () => {
  useScript("./assets/js/scripts.js");
  const pagerefresh = () => {
    window.location.pagerefresh();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showBtn, setShowBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);
  return (
    <>
      {showBtn && <Backbutton />}
      <Header />
      <div id="page" data-barba="container">
        <div id="content" className="page-content indexing">
          <div className="alioth-post">
            <div className="post-header">
              <div className="post-image">
                <img alt="Post Image" src={ukneedswebsitecover} />
              </div>
              <div className="post-details-wrapper wrapper-small">
                <div className="c-col-12">
                  <div className="post-metas single_date">
                  </div>
                  <div className="post-title">
                    <h1>
                      Terms & Conditions
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="post-content wrapper-small no-margin">
              <div className="section">
                <div className="wrapper-small no-margin single_blog_contents">
                  <div className="c-col-12">
                    <div className="text-wrapper">
                        <div className="post-title">
                          <h4>
                            OVERVIEW
                          </h4>
                        </div>
                        <p>
                        This website is operated by TIGER IT SOLUTION (the brand, operated by MB TIGER IT SOLUTION, a company registered in Lithuania, registration number 305711600, hereinafter – us, we or the MB TIGER IT SOLUTION) (hereinafter – Website). Throughout the Website, the terms “we”, “us” and “our” refer to MB TIGER IT SOLUTION. MB TIGER IT SOLUTION offers this Website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.<br/><br/>The Website offers the following services, including, but not limited to: digital marketing, e-commerce support, organizing photo-shoots and/or pop-ups, etc. (hereinafter – Services).<br/><br/>Please read these terms and conditions (hereinafter – Terms & Conditions, Terms) carefully before accessing or using our Website. By accessing or using any part of the Website, you agree to be bound by these Terms, including additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms apply to all users of the Website, including without limitation users who are browsers, vendors, customers, merchants, and/or contributors of content. If you do not agree to all the terms and conditions of this agreement, then you may not access the Website or use any of the provided tools.<br/><br/>Any new services and/or tools, which are added to the current Website, shall also be subject to the Terms.<br/><br/>You can review the most current version of the Terms & Conditions at any time on this page. We reserve the right to update, change or replace any part of these Terms by posting updates and/or changes to our Website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the Website following the posting of any changes constitutes acceptance of those changes.                        
                        </p>
                        <h4>1. GENERAL CONDITIONS</h4>
                        <p>
                        You may not use our Services for any illegal or unauthorized purpose nor may you, in the use of the Services, violate any laws in your jurisdiction (including but not limited to copyright laws).<br/><br/>A breach or violation of any of the Terms will result in an immediate termination of use of Website or Services.<br/><br/>We reserve the right to refuse providing Services to anyone for any reason at any time.<br/><br/>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Services, use of the Services, or access to the Services or any contact on the Website through which the Services are provided/purchased, without express written permission by us.
                        </p>
                        <h4>2. ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</h4>
                        <p>
                        We shall put our best effort to make the information made available on this Website accurate, complete and current. However, we do not guarantee, represent or warrant that your use of Website will be uninterrupted, timely, secure or error-free. You agree that from time to time we may remove the access to the Website for indefinite periods of time.<br/><br/>While all the effort is made to ensure the content of this Website is up to date and accurate, the Website is provided “as is” and www.tigersolution.co.uk makes no representations or warranties in relation to the accuracy or completeness of the information provided therein. We do not warrant that the information will be kept up to date, be not misleading.<br/><br/>The material on this Website is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.<br/><br/>We reserve the right to modify the contents of this Website at any time, but we have no obligation to update you on any information change on Website. You agree that it is your responsibility to monitor changes to Website.
                        </p>
                        <h4>3. ERRORS, INACCURACIES AND OMISSIONS</h4>
                        <p>
                        Occasionally there may be information on the Website that contains typographical errors, inaccuracies or omissions that may relate to Services descriptions, pricing, promotions, offers and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Website is inaccurate at any time without prior notice (including after you have submitted your order).<br/><br/>We undertake no obligation to update, amend or clarify information in the Website or on any related website/policy, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Website or on any related website/policy should be taken to indicate that all information in the Website or on any related website/policy has been modified or updated.
                        </p>
                        <h4>4. SERVICES AND MODIFICATIONS TO THE SERVICES AND PRICES</h4>
                        <p>
                        All descriptions of Services or its’ pricing (in case it is posted on the Website) are subject to change at anytime without notice, at the sole discretion of us.<br/><br/>We reserve the right at any time to modify or discontinue offering a certain Service (or any part or content thereof) without notice at any time. Any offer for any Service made on this Website is void where prohibited. We shall not be liable for any modification, price change, suspension or discontinuance of the Service.<br/><br/>We reserve the right, but are not obligated, to limit the sales of our Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis.
                        </p>
                        <h4>5. PERSONAL INFORMATION</h4>
                        <p>
                        Collection and use of personal information while using the Website is governed by our Privacy Policy.
                        </p>
                        <h4>6. ACCURACY OF BILLING AND ACCOUNT INFORMATION</h4>
                        <p>
                        In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/phone number provided at the time the order was made.<br/><br/>We reserve the right to limit or refuse orders that, in our sole judgment, appear to be not in line with WFMA business objectives, ideas and creative vision.<br/><br/>You agree to provide current, complete and accurate information for all purchases. You agree to promptly update your contact information, including your email address, so that we can contact you as needed.
                        </p>
                        <h4>7. PAYMENT FOR THE SERVICES</h4>
                        <p>
                        The payment for the Services shall take place using an external service provider, (e.g. PayPal or other service provider used by us at that time) or via wire bank transfer.<br/><br/>In case the payment shall be done via an external service provider, you shall follow the instructions provided by the external service provider. The payment shall be subject to the terms and conditions of the said service provider.<br/><br/>In case the payment shall be done via wire bank transfer, we shall forward you an invoice to the email address, which you have provided us with. The invoice shall be settled within 7 days after receiving it.<br/><br/>The Services shall be provided only after the payment for the Services has been made, unless the parties have agreed otherwise.
                        </p>
                        <h4>8. EXTERNAL LINKS</h4>
                        <p>
                        We may provide you with access to third-party links, which we neither monitor, nor have any control of, nor input. Certain content available via our Website may include materials from third parties.<br/><br/>You acknowledge and agree that we provide access to such websites ”as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party links/tools.<br/><br/>Third-party links on this Website may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.<br/><br/>We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit, before engaging into any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.<br/><br/>Any use by you of optional tools offered through the third-party site is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s).
                        </p>
                        <h4>9. USER COMMENTS AND TESTIMONIES</h4>
                        <p>
                        At your own discretion, you are welcome to submit testimonies regarding our Services. The testimonies shall reflect your experience with us.<br/><br/>In the event, that the testimony or a comment shall be deemed to be fake (not reflecting a real user experience) or contain unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable language or violates any party’s intellectual property or these Terms & Conditions, we may, but have no obligation to, monitor, edit or remove content that we determine as such in our sole discretion.<br/><br/>You agree that we may, at any time, without restriction: copy, publish, distribute, translate and otherwise use in any medium any testimonies that you submit/forward to us. We are and shall be under no obligation to: (1) maintain any testimonies in confidence (unless requested by a customer); (2) to pay compensation for any testimonies; or (3) to respond to any testimonies.<br/><br/>You agree that your testimonies will not violate any right of any third party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your testimonies will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Website or any related website. You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third parties as to the origin of any testimonies. You are solely responsible for any testimonies you make and their accuracy. We take no responsibility and assume no liability for any testimonies posted by you or any third-party.
                        </p>
                        <h4>10. NEWSLETTER</h4>
                        <p>
                        Upon your confirmation, we shall send you a periodic newsletter, containing information regarding Services, promotions, offers, etc.<br/><br/>At any given time, you shall have the right to unsubscribe from our newsletter, by following the un-subscription instructions provided in our email.
                        </p>
                        <h4>11. PROHIBITED USES</h4>
                        <p>
                        In addition to other prohibitions as set forth in the Terms & Conditions, you are prohibited from using the Website or its content:<br/><br/>(a) for any unlawful purpose;<br/><br/>(b) to solicit others to perform or participate in any unlawful acts;<br/><br/>(c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances;<br/><br/>(d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others;<br/><br/>(e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability;<br/><br/>(f) to submit false or misleading information;<br/><br/>(g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Website or of any related website, other websites, or the Internet;<br/><br/>(h) to collect or track the personal information of others;<br/><br/>(i) to spam, phish, pharm, pretext, spider, crawl, or scrape;<br/><br/>(j) for any obscene or immoral purpose; or<br/><br/>(k) to interfere with or circumvent the security features of the Website or any related website, other websites, or the Internet.<br/><br/>We reserve the right to terminate your use of the Website or any related website for violating any of the prohibited uses.
                        </p>
                        <h4>12. DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</h4>
                        <p>
                        In no case shall TIGER IT SOLUTION, our affiliates, agents, employees, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the Website or any Services procured using the Website, or for any other claim related in any way to your use of the Website, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the Website or any content posted, transmitted, or otherwise made available via the Website, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.
                        </p>
                        <h4>13. INDEMNIFICATION</h4>
                        <p>
                        You agree to indemnify, defend and hold harmless TIGER IT SOLUTION and our parent, subsidiaries, affiliates, partners, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms & Conditions or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.
                        </p>
                        <h4>14. SEVERABILITY</h4>
                        <p>
                        In the event that any provision of these Terms & Conditions is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms & Conditions, such determination shall not affect the validity and enforceability of any other remaining provisions.
                        </p>
                        <h4>15. TERMINATION</h4>
                        <p>
                        The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.<br/><br/>These Terms are effective unless and until terminated by either you or us. You may terminate these Terms at any time by notifying us that you no longer wish to use our Services, or when you cease using our Website.<br/><br/>If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Website and/or Services (or any part thereof).
                        </p>
                        <h4>16. ENTIRE AGREEMENT</h4>
                        <p>
                        The failure of us to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision.<br/><br/>These Terms and any policies or operating rules posted by us on this Website or in respect to the Services constitutes the entire agreement and understanding between you and us and govern your use of the Website, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms & Conditions).<br/><br/>Any ambiguities in the interpretation of these Terms shall not be construed against the drafting party.
                        </p>
                        <h4>17. GOVERNING LAW</h4>
                        <p>
                        These Terms & Conditions and any separate agreements related to the use of the Website shall be governed by and construed in accordance with the laws of United Kingdom.
                        </p>
                        <h4>18. CHANGES TO TERMS OF SERVICE</h4>
                        <p>
                        You can review the most current version of the Terms at any time at this page.<br/><br/>We reserve the right, at our sole discretion, to update, change or replace any part of these Terms & Conditions by posting updates and changes to our Website. It is your responsibility to check our Website periodically for changes. Your continued use of or access to our Website following the posting of any changes to these Terms & Conditions constitutes acceptance of those changes.
                        </p>
                        <h4>19. CONTACT INFORMATION</h4>
                        <p>
                        Questions about the Terms of Service should be sent to us at info@tigersolution.co.uk Feel fee to contact us.
                        </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
