import React from "react";
import { Link } from "react-router-dom";

export const HomeSixSec = () => {
  const pagerefresh = () => {
    window.location.pagerefresh();
  };

  return (
    <div className="section">
      <div className="wrapper indexing">
        <div className="c-col-12">
          <div className="text-wrapper">
            <h1
              className="has-anim small-title"
              data-stagger="0.01"
              data-duration="1.2"
              data-delay="0"
              data-animation="linesUp"
            >
              Are you looking for web design agency in UK? We provide
              hassle-free web design services that your business needs.
            </h1>
          </div>
        </div>
        <div className="c-col-4 hide_mobile"></div>
        <div className="c-col-8">
          <div className="text-wrapper">
            <p
              style={{ color: "rgba(255, 255, 255, .7)" }}
              className="has-anim"
              data-stagger="0.05"
              data-duration="1.25"
              data-delay="1"
              data-animation="linesUp"
            >
              With a sustainable and fast-growing market, UK keeps drawing the
              attention of investors from both local and foreign regions, paving
              the way to unending competition among businesses which primarily
              aim to establish their online presence to attract customers in UK.
              What can be better than a creative and responsive website with an
              engaging and intuitive UI? Again, a website that is good at
              serving your ecommerce consumers may not be good at all when it
              comes to persuading corporate stakeholders in UK. Our designers
              and developers in UK understand this simple fact and act
              accordingly to the clients' benefits.
            </p>
            <span
              className="a-empty-space hide_desktop"
              style={{ height: "20px" }}
            ></span>
            <p
              style={{ color: "rgba(255, 255, 255, .7)" }}
              className="has-anim"
              data-stagger="0.1"
              data-duration="1.25"
              data-delay="1"
              data-animation="linesUp"
            >
              Every designer and developer at our company in UK possess the
              required skills plus one special thing - understanding of how
              customers/ audiences in UK behave as they come across a website or
              web application. This knowledge helps them to realize the pain
              points of customers which are never good for business, even if
              it's about a billion-dollar corporate company in UK. Designers and
              developers at our agency in UK try to keep the UI and functional
              aspects as simple yet engaging as possible so that users feel like
              interacting with the website and their experience remains
              positive.
            </p>
          </div>
          <span className="a-empty-space" style={{ height: "30px" }}></span>
          <span
            data-color="hsla(0,0%,100%,.4)"
            data-anim="true"
            className="alioth-seperator"
          ></span>
          <span className="a-empty-space" style={{ height: "30px" }}></span>

          <div className="a-button style_1">
            <span
              className="button-overlay"
              style={{ top: "1467px", left: "27px", height: "0%", width: "0%" }}
            ></span>
            <Link to="/contact-us" onClick={pagerefresh}>
              Start A Project Now
            </Link>
            <i className="icofont-arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
  );
};
