import React, { useEffect, useState } from "react";
import { First } from "../Common/First";
import { Five } from "../Common/Five";
import { Four } from "../Common/Four";
import { Second } from "../Common/Second";
import { Six } from "../Common/Six";
import { Third } from "../Common/Third";
import creativewebsite from "./../../images/pages/creative_website.webp";
import creative from "./../../images/pages/creative.webp";
import creativewebdesigncompany from "./../../images/creative_web_design_company.webp";
import "./../../css/style.css";
import "./../../css/inner.css";
import "./../../css/plugins.css";
import useScript from "./../useScript";
import { Backbutton } from "../Common/Backbutton";
export const CreativeWebDesign = () => {
  useScript("./assets/js/scripts.js");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showBtn, setShowBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);
  return (
    <>
      {showBtn && <Backbutton />}
      <div id="page" data-barba="container">
        <div id="content" className="page-content">
          <First
            head1="Creative"
            head2="web design."
            para="Creative design is the reason one in ten company websites in London, UK wow its visitors with amazing experiences, whilst the rest cannot. A creative website design agency takes creativity as the source of innovation and inspiration to transcend user experience that encompasses the entirety of users' interaction with its client's company, services, and products."
            img={creativewebsite}
          />
          <Second
            secondheading1="If creative UI and UX lead to higher conversion"
            secondheading2="Creative web design is never out of the equation"
            img={creative}
          />
          <Third
            head4="As each of our creative website designers grew up in London, UK, being customers themselves and knowing others in the process, they're familiar with things that appeal to others. With these real-life experiences, they keep learning and honing their web design skills which include the art of creative design on top. It's part of our strength as a creative web design agency in London, UK."
            p1="There have been many ideas surrounding the concept of creative website design introduced to the web design and development community in London, UK. Some of them suggest that the use of a striking colour scheme and design elements based on variable shapes is enough to bring in the creativity of a website design. In practice, it is not just about designing something exclusive or extraordinary. It is about taking traditional or out-of-the-box web design approaches to create a website that helps a company by solving its problem, boosting its productivity and sales, and anything that matters."
            p2="The number of websites that currently exist is more than 1.7 billion. It's next to impossible to find any business area in London, UK where no other sites are competing for the attention of the same people you're targeting. How do you see your business website making any mark? You could say 'by proposing something unique. But every other business has got a Unique Selling Proposition (USP). The only way to stand out is to adopt a creative website design. Not just any website design agency in London, the UK can excel in it. Your company should only rely on a creative agency that has years of experience in delivering a design that sells."
            linkname="Start A Project Now"
          />

          <Four img={creativewebdesigncompany} />

          <Five
            h1="Are you looking for a creative website design agency in London, UK? We design nice-looking websites that your business needs."
            p1="Let's get into creativity as a principle of the website design process followed by our creative agency in London, UK. People in London, UK are accustomed to visiting numerous websites that present them colourful imagery, eye-catching visuals, and lots of other UI elements. A splash of matching colors, natural scrolling, and linear or visual storytelling can be part of a great website design, but a lot of companies in London, UK are doing the same. How do you think your company website can occupy a place in their hearts?"
            p2="Our creative website designers know the secrets of crafting memorable website designs that make brands easily identifiable and worth remembering in London, UK. Isn't it good for your company in London, UK to see its audience getting a sense of warmth, credibility, and familiarity the second they land on any of your web pages? As easy and good as it may sound, the job is never easy without the knowledge and experience fusing creative tactics into your agency's branding assets in which the website makes the most important part."
            p3="You might have been familiar with these creative ideas, but you still need a website design agency that maintains a laudable record of doing creative work for businesses in London, UK. We pride ourselves in being a creative agency that has earned great respect from companies in different sectors where creativity is never optional but the essence of everything they stand for. So, getting the chance to work with another company like yours only makes us confident of delivering creative work one more time."
            linkname="Start A Project Now"
          />
          <Six link1="Start a Project" link2="Request for a Quotation" />
        </div>
      </div>
    </>
  );
};
