import React, { useEffect, useState } from "react";
import { First } from "../Common/First";
import { Five } from "../Common/Five";
import { Four } from "../Common/Four";
import { Second } from "../Common/Second";
import { Six } from "../Common/Six";
import { Third } from "../Common/Third";
import interactivewebsite from "./../../images/pages/interactive-website.webp";
import interactivewebsitedesign from "./../../images/pages/interactive_website_design.webp";
import interactive from "./../../images/interactive.webp";
import "./../../css/style.css";
import "./../../css/inner.css";
import "./../../css/plugins.css";
import useScript from "./../useScript";
import { Backbutton } from "../Common/Backbutton";
export const InteractiveWebDesign = () => {
  useScript("./assets/js/scripts.js");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showBtn, setShowBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);
  return (
    <>
      {showBtn && <Backbutton />}
      <div id="page" data-barba="container">
        <div id="content" className="page-content">
          <First
            head1="Interactive"
            head2="website design."
            para="An interactive website is only good as long as it helps you forge a strong relationship with your visitors. Typical website designers in London, UK may believe 'Scrollytelling' alone can make a website interactive and engaging. At our London, UK based website design agency, designers use next-generation technologies and web features, maintaining the subtlety of interaction design to engage the audience of the client's company."
            img={interactivewebsite}
          />
          <Second
            secondheading1="Interactive website design is not just about 'scroll' or 'click'"
            secondheading2="It's about creating UI with human behaviors in mind"
            img={interactivewebsitedesign}
          />
          <Third
            head4="Having some of the most accomplished website designers in London, UK, our design agency can ensure that each interactive website that we design and develop complements the goals of your company; inspires effective actions and long-lasting engagement; creates positive, personalised experiences for your valued customers; and brings more conversion from website."
            p1="The majority of web traffic in London, UK, like others around the world, takes only 10 seconds to decide whether or not they're going to spend a little more time on your company website. It means you only have that long to tell the story of your brand and engage them in more interactions with your website than mere scrolling and clicking. Our brilliant website designers in London, UK can use their imaginations, immersive UI elements, and powerful technologies to make your visitors feel like doing more on your website."
            p2="Our interactive website design and development agency is proud to have expert designers and developers in London, UK who have a strong understanding of the various dimensions of interactive website designs. They have a proven ability to improve the interactive experience of users as they visit and interact with a website or its content. Lots of businesses in London, UK have recognised the value of an interactive website yet only a handful of website design agencies can really offer a masterly visualisation of images, words, space, and time based on user behaviors. Luckily, it's part of the daily activities handled by our talented web designers and developers."
            linkname="Start A Project Now"
          />

          <Four img={interactive} />

          <Five
            h1="Are you looking for a interactive website design agency in London, UK? We design interactive websites that your business needs."
            p1="An interactive website helps a company serve several obvious purposes in addition to the conventional ones that call for an interactive web presence. If your target market is London, UK, you surely got an appreciative audience that may not bounce immediately upon seeing something compelling. An interactive website adds to your effort to lower the bounce rate, which hurts your company's bottom line in every possible way. Just imagine yourself in their position, and you'll know why many companies in London, UK experience bounce visits."
            p2="Our website design and development agency in London, UK adopts proven techniques to approach interactivity. Setting the goal to evoke engagement as visitors stop by an interactive website, we make sure they don't just leave without being entertained and convinced to take actions. Our skilled designers and developers in London, UK take each step of the process carefully so that the website with all its interactive features and elements loads fast, offers visitors the value they expect, and allows them to perform the intended actions without barriers."
            p3="While designing an interactive website, developers and designers at our agency in London, UK focus on letting users do more with less effort and without experiencing cognitive loads to understand their way around. Web designers ensure consistency among the UI elements to invite interactions while developers at our agency maintain functional minimalism. In doing so, our web design and development professionals in London, UK take inspiration from real-world interactions to match user expectations and gain their trust. Thus, we've pitched in our effort to help many clients in London, UK."
            linkname="Start A Project Now"
          />
          <Six link1="Start a Project" link2="Request for a Quotation" />
        </div>
      </div>
    </>
  );
};
