import React, { useEffect, useState } from "react";
import { First } from "./Common/First";
import { Five } from "./Common/Five";
import { Four } from "./Common/Four";
import { Second } from "./Common/Second";
import { Third } from "./Common/Third";
import { Six } from "./Common/Six";
import aboutus from "./../images/pages/about_us.webp";
import tigerit from "./../images/pages/about_tiger_it.webp";
import inner from "./../images/inner.webp";
import useScript from "./useScript";
import "./../css/style.css";
import "./../css/inner.css";
import { Backbutton } from "./Common/Backbutton";
export const Aboutus = (props) => {
  useScript("./assets/js/scripts.js");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showBtn, setShowBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);
  return (
    <>
      {showBtn && <Backbutton />}
      <div id="page" data-barba="container">
        <div id="content" className="page-content">
          <First
            head1="Hello,"
            head2="we are Tiger IT Solutions."
            para="We don't claim to be the best web design agency in London, UK, but we can certainly become
                            your go-to website design and development company that understands your business pain points
                            and strives to help you overcome them without spending a fortune."
            img={aboutus}
          />
          <Second
            secondheading1="Our reputation is built upon genuine success, not just words"
            secondheading2="And we define success by clients' satisfaction, not numbers"
            img={tigerit}
          />
          <Third
            head4="Since the first day we entered the competitive business of developing and designing websites
                        for our clients in London, UK, we've held a few characteristics that work as the driving
                        force of our company as we successfully fulfill the requests of a client and await the
                        arrival of the next. Dedication and passion for excellence are the two most important traits
                        of our designers and developers."
            p1=" Tiger IT Solutions is a fast-growing web design and development agency looking to make life easier
                        for small businesses, professional individuals, midsize companies, and large corporations in
                        London, UK by designing creative, fast-loading, responsive, user-friendly, and eye-catching
                        websites within budget. We believe in client acquisition as much as we do in retention."
            p2=" Our portfolio includes virtually all kinds of legitimate companies operating within the
                        business sector in London, UK. Our talented web design and development professionals enjoy
                        working on diverse projects that often come with some very specific or highly challenging
                        requirements. Whether yours is a small or medium company, or you're representing a large
                        corporation that needs a stunning look and an efficient functional side, we've got the
                        numbers to impress you. But we take a different approach. We try to go deeper into your
                        business objectives and help you set the right goals for your company as to establishing an
                        online identity and building reputation in London, UK."
            linkname="Start A Project Now"
          />
          <Four img={inner} />
          <Five
            h1="Are you worried about your website or your branding?
                        Let's build something great together!"
            p1=" While most of our clients in London, UK are from the finance, F & B, logistics, fashion,
                        health and fitness, manufacturing, engineering, and corporate sectors, we've helped
                        quite a considerable number of clients running small/ local companies to serve a very
                        limited amount of people. So, our experience has never been the same, but the only thing
                        that remained constant. That is our commitment to bring satisfaction to our clients in
                        London, UK. To keep this up, we design our work strategy based on each client and their
                        expectations."
            p2=" Being a web design and development agency in London, UK, our workforce consists of
                        designers and developers for the larger part, but we also have diligent researchers and
                        analysts on our team to bring you the best possible experience working with us. The
                        whole team of designers and developers boasts decades of true and tried experience
                        working in London, UK. Apart from skills and years, they're capable of understanding what
                        clients want or have to say and act accordingly toward a positive and successful end."
            p3=" None of what we've accomplished so far or what we, as a web design and development
                        agency in London, UK, are working to achieve would be possible without a mission. For us,
                        it is to emerge as the first agency that comes to mind when a business or individual
                        starts looking for a web design and development agency in London, UK. We are set to walk
                        on the path and overcome the challenges that come with it. We envision an environment
                        where clients as well as the audience always have the means to appreciate and benefit
                        from amazing and user-friendly websites."
            linkname="Start A Project Now"
          />
          <Six link1="Start a Project" link2="Request for a Quotation" />
        </div>
      </div>
    </>
  );
};
