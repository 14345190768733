import React from "react";
export const First = (props) => {
    return (
        <div className="wrapper title_container">
            <div className="c-col-1 hide_mobile">
            </div>
            <div className="c-col-10">
                <div className="inner-page-title">
                    <h1 className="has-anim" data-stagger="0.1" data-duration="1" data-delay=".1"
                        data-animation="linesUp">{props.head1} <br />{props.head2}</h1>
                    <p className="has-anim" data-stagger="0.1" data-duration="1" data-delay="1"
                        data-animation="linesUp">{props.para}
                    </p>
                    <a className="circular-button scroller" href="#inner_content">
                        <span>Learn More<i className="icofont-arrow-down"></i></span>
                    </a>
                </div>
                <img className="title_image" alt="Web design agency in London, UK" src={props.img} />
            </div>
        </div>
    );
}