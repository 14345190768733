import React, { useEffect, useState } from "react";
import { First } from "../Common/First";
import { Five } from "../Common/Five";
import { Four } from "../Common/Four";
import { Second } from "../Common/Second";
import { Six } from "../Common/Six";
import { Third } from "../Common/Third";
import useScript from "./../useScript";
import wordpress from "./../../images/pages/wordpress.webp";
import wordpresswebsitedesign from "./../../images/pages/wordpress_website_design.webp";
import wordpressdesign from "./../../images/wordpress_design.webp";
import "./../../css/style.css";
import "./../../css/inner.css";
import { Backbutton } from "../Common/Backbutton";
export const WordPressWebsiteDesign = () => {
  useScript("./assets/js/scripts.js");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showBtn, setShowBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);
  return (
    <>
      {showBtn && <Backbutton />}
      <div id="page" data-barba="container">
        <div id="content" className="page-content">
          <First
            head1="WordPress"
            head2="website design."
            para="To date, WordPress has been used to design one-third of all websites including the ones with a custom-developed content management system (CMS) and those without a CMS. So, it makes sense for both a website design agency in London, UK and a company looking for a website to choose WordPress over any other CMS."
            img={wordpress}
          />
          <Second
            secondheading1="The popular WordPress CMS has so much to offer"
            secondheading2="We unleash its power to promote your business"
            img={wordpresswebsitedesign}
          />
          <Third
            head4="Our WordPress website designers in London, UK know how to enhance your business identity using a healthy and thoughtful blend of visuals. WordPress website designers and developers at our agency develop the necessary technical elements to ensure that your site is accessible and navigable without a learning curve."
            p1="Each WordPress website designer and developers at Tiger IT Solutions has mastered the essential website design skills to create WordPress websites that perform just as well as you expect. WordPress being adopted as an evolving commercial tool makes it a necessity for our WP website designers to have knowledge about businesses in London, UK. Thus, our WordPress website designing agency has the resources to help you all the way from designing a WP website and ensuring its security."
            p2="As a WordPress website design and development agency in London, UK, we've helped a lot of businesses in London, UK with both front-end and back-end requirements. Our WordPress developers and designers have proven skills in all areas of WP development from designing a wireframe to creating WordPress themes and redesigning an existing WP website. Our clientele includes both small businesses and large enterprises in London, UK whose expectations are specific to different industries. We pride ourselves in being able to assist them in the most efficient and cost-effective manner in London, UK."
            linkname="Start A Project Now"
          />

          <Four img={wordpressdesign} />

          <Five
            h1="Are you looking for WordPress website design agency in London, UK? We design amazing WordPress websites that your business needs."
            p1="Our WordPress website design and development services in London, UK include WordPress theme design and development, WordPress website customisation, WordPress website maintenance and WordPress plugin development. Most clients usually come up with their preferred WordPress theme. Our WP website designers use it as a parent theme to design a child theme that helps us speed up our design and development process. Although this is how we work on many of our WordPress website projects in London, UK, our agency has vast experience in offering other solutions for a London, UK-based company."
            p2="Being very particular about the look and style, most of the clients ask us to design a WordPress theme from scratch and then develop a website using the theme. We engage our most experienced designers and developers on such projects where the final product (a full-featured WordPress theme) is easy to customise. While handling a project like that, our designers choose the fonts, colour schemes, typefaces, and overall aesthetics carefully in the WordPress theme."
            p3="On occasions, some of our clients in London, UK request us to develop or use plugins that can help them enhance the aesthetics and functionality of their WordPress website. We recommend them the best plugins as well as develop them if required. So, we are comfortable with both customization and development of plugins. Over the years, our agency has earned a great reputation as a reliable company when it comes to anything about WordPress website design in London, UK."
            linkname="Start A Project Now"
          />
          <Six link1="Start a Project" link2="Request for a Quotation" />
        </div>
      </div>
    </>
  );
};
