import React, { useEffect, useState } from "react";
import { First } from "../Common/First";
import { Second } from "../Common/Second";
import { Third } from "../Common/Third";
import { Four } from "../Common/Four";
import { Five } from "../Common/Five";
import { Six } from "../Common/Six";
import "./../../css/style.css";
import "./../../css/inner.css";
import "./../../css/plugins.css";
import useScript from "./../useScript";
import awardwiningdevelopment from "./../../images/pages/award_wining_development.webp";
import awardwininguk from "./../../images/pages/award_wining_UK.webp";
// import awardwiningdevelopment1 from "./../../images/award_wining_development.webp"
import { Backbutton } from "../Common/Backbutton";
export const AwardWinningWebDevelopment = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useScript("./assets/js/scripts.js");
  const [showBtn, setShowBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);
  return (
    <>
      {showBtn && <Backbutton />}
      <div id="page" data-barba="container">
        <div id="content" className="page-content">
          <First
            head1="Award winning"
            head2="web development."
            para="An award winning website is one that instantly captures the attention of visitors. The best way to make a solid first impression is to tell people what your company is all about. But how do you create a personalised experience for your customers while telling the story of your company? Here comes Tiger IT Solutions, an award winning web development company in London, UK."
            img={awardwiningdevelopment}
          />

          <Second
            secondheading1="An award winning website not only attracts visitors"
            secondheading2="But also increase conversion rate of your business' satisfaction, not numbers"
            img={awardwininguk}
          />

          <Third
            head4="It's not just about design or sophistication that makes a website an award winning one. Creating an award winning website is to incorporate interactive features, responsiveness, streamlined navigation, stylish visuals, and lightning-fast loading speed. Our creative web design and development artists build each part of the UI of a website using a lively focus on the key elements to allow the visitors to be immersed in the unique experience they could have with your brand in London, UK."
            p1=" Aside from design and functionality, many other factors go into developing an award winning website, and we'll be happy to discuss them with you upon your request in London, UK. Web designers and developers at Tiger IT Solutions can discover the best practices implemented on that website and incorporate them into a more engaging website design. Web designers and developers at our company have extensive knowledge of gaining traction with intelligent audiences."
            p2="	We have a dynamic team of web developers and designers with a wealth of knowledge and insight into various industries in London, UK, enabling our designers and developers to meet the specific demands of each client. However, not every company in London, UK strives for an award when building a website. Therefore, our expert team can handle it practically by identifying what's already great and improving upon it so that you can create a brand in London, UK that becomes an example in its field."
            linkname="Start A Project Now"
          />

          <Four img={awardwiningdevelopment} />

          <Five
            h1="Are you looking for a award winning web development company in London, UK? We develop nice-looking websites that your business needs."
            p1=" As an award winning web development and design company in London, UK, we understand what it takes to gain traction with a savvy audience. Thus, we are better positioned to serve our clients with this real-life experience. In addition, our team possesses an abundance of knowledge of the recent trends and competitiveness. Our websites have earned multiple awards from some esteemed competition bodies in London, UK."
            p2=" Although our award winning sites represent our skills and expertise, they also reflect our persistence in staying ahead of the competition. It allows us to benefit our clients in London, UK. In addition to this, our creative web developers and designers never stop striving to come up with awesomeness for our clients in London, UK in everything we design and deliver."
            p3="We are confident that we can create a creative award winning website that makes your brand as well as company recognizable among the thousands of others in London, UK within seconds. Every business or agency in London, UK takes pride in having an award winning website. Call us if you need support or have other questions about our web development services. Feel free to ask for a quote."
            linkname="Start A Project Now"
          />
          <Six link1="Start a Project" link2="Request for a Quotation" />
        </div>
      </div>
    </>
  );
};
