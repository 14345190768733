import React, { useEffect, useState } from "react";
import { First } from "../Common/First";
import { Five } from "../Common/Five";
import { Four } from "../Common/Four";
import { Second } from "../Common/Second";
import { Six } from "../Common/Six";
import { Third } from "../Common/Third";
import ecommerce from "./../../images/pages/ecommerce.webp";
import ecommercedesign from "./../../images/pages/ecommerce_design.webp";
import ecommercewebsitedesign from "./../../images/ecommerce_website_design.webp";
import "./../../css/style.css";
import "./../../css/inner.css";
import "./../../css/plugins.css";
import useScript from "./../useScript";
import { Backbutton } from "../Common/Backbutton";
export const EcommerseWebsiteDesign = () => {
  useScript("./assets/js/scripts.js");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showBtn, setShowBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);
  return (
    <>
      {showBtn && <Backbutton />}
      <div id="page" data-barba="container">
        <div id="content" className="page-content">
          <First
            head1="Ecommerce"
            head2="website design."
            para="Any ecommerce company in London, UK could be happy to have a website that accepts orders, takes payments, handles logistics, tracks shipments, and provides customer services. But ecommerce businesses are not entirely about selling products and making money. A website design agency should know better than this."
            img={ecommerce}
          />
          <Second
            secondheading1="With an ecommerce website, you're not just selling products"
            secondheading2="You're making life easier and more fulfilling for your customers"
            img={ecommercedesign}
          />
          <Third
            head4="Most ecommerce website design agencies in London, UK see an ecommerce website as an alternative to the brick-and-mortar store, but our ecommerce web design and development agency in London, UK holds a different view in that ecommerce websites are meant to make people believe they're in control and everything is at their fingertips."
            p1="Once you let our agency design and develop an ecommerce website for your company; you would never have to worry about a few things, such as responsive and mobile-friendly design, customer-centric navigation, high-quality visuals, fast-loading web pages, easy-to-use checkout and order process, CTAs, etc. Our designers and developers in London, UK plan everything right from the beginning with vigilant eyes on details and consumers' convenience in mind. Ecommerce analysts working at the agency put due diligence to study the demographics of your target customers and your business objectives. Thus, we minimise every possibility of getting anything wrong."
            p2="The lack of originality, creativity, and care about the consumers' preferences hurts the bottomline of many ecommerce companies in London, UK. An attention to this hard truth enables us to streamline our work process for ecommerce website design projects so that the end product is an amazing user interface that grabs users' attention just the second they stop by your website and keeps them engaged for as long as they take to know about your brand and what it's offering. Our website designers and developers in London, UK put every single design element of your website in a way that helps keep up the user flow and leads to higher conversions."
            linkname="Start A Project Now"
          />

          <Four img={ecommercewebsitedesign} />

          <Five
            h1="Are you looking for a creative website design agency in London, UK? We design nice-looking websites that your business needs."
            p1="Let's get into creativity as a principle of the website design process followed by our creative agency in London, UK. People in London, UK are accustomed to visiting numerous websites that present them colourful imagery, eye-catching visuals, and lots of other UI elements. A splash of matching colors, natural scrolling, and linear or visual storytelling can be part of a great website design, but a lot of companies in London, UK are doing the same. How do you think your company website can occupy a place in their hearts?"
            p2="Our creative website designers know the secrets of crafting memorable website designs that make brands easily identifiable and worth remembering in London, UK. Isn't it good for your company in London, UK to see its audience getting a sense of warmth, credibility, and familiarity the second they land on any of your web pages? As easy and good as it may sound, the job is never easy without the knowledge and experience fusing creative tactics into your agency's branding assets in which the website makes the most important part."
            p3="You might have been familiar with these creative ideas, but you still need a website design agency that maintains a laudable record of doing creative work for businesses in London, UK. We pride ourselves in being a creative agency that has earned great respect from companies in different sectors where creativity is never optional but the essence of everything they stand for. So, getting the chance to work with another company like yours only makes us confident of delivering creative work one more time."
            linkname="Start A Project Now"
          />
          <Six link1="Start a Project" link2="Request for a Quotation" />
        </div>
      </div>
    </>
  );
};
