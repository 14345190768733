import React from "react";
export const HomeSecondSec = () => {
    return (
        <div className="section" id="secondSec">
            <div className="wrapper indexing">
                <span className="a-empty-space hide_mobile" style={{ height: "150px" }}></span>
                <span className="a-empty-space hide_desktop" style={{ height: "80px" }}></span>
                <div className="c-col-12">
                    <div className="text-wrapper">
                        <h1 className="has-anim medium-title" 
                        data-stagger="0.01" data-duration="1.2" data-delay="0" data-animation="linesUp">
                            Hello, we are Tiger IT Solutions. A creative web design & development company from London, UK.
                        </h1>
                    </div>
                </div>
                <div className="c-col-4 hide_mobile"></div>
                <div className="c-col-8">
                    <div className="text-wrapper">
                        <h6 style={{ color: "rgba(255, 255, 255, 70%)" }} className="has-anim" data-stagger="0.05"
                            data-duration="1.25" data-delay="1" data-animation="linesUp">
                            Designing a website is like creating a story, and that's what our web designers and
                            developers do. Similarly, developing a website to make it functional is like shaping the
                            journey of visitors as they land on a website. Our designers and developers take their
                            jobs seriously whether they design or develop a website or web application for a small
                            or midsize company in London, UK. If the past decade has taught us, as a web design and
                            development agency, anything about businesses in London, UK, it's the lesson that
                            innovation keeps you alive. So, we seek and combine innovation into anything we do for
                            our clients in London, UK.
                        </h6>
                    </div>
                    <span className="a-empty-space" style={{ height: "30px" }}></span>
                    <span data-color="hsla(0,0%,100%,.4)" data-anim="true" className="alioth-seperator"></span>
                    <span className="a-empty-space" style={{ height: "30px" }}></span>
                </div>
                <div className="c-col-4 hide_mobile">
                </div>
                <div className="c-col-4">
                    <div data-delay="0" className="a-number-counter">
                        <div className="ac-number">135</div>
                        <span className="ac-sign">+</span>
                        <div className="ac-title">Happy Clients in UK</div>
                    </div>
                    <span className="a-empty-space counter_empty_height"></span>
                    <div data-delay="0" className="a-number-counter">
                        <div className="ac-number">260</div>
                        <span className="ac-sign">+</span>
                        <div className="ac-title">Websites Designed</div>
                    </div>
                </div>
                <div className="c-col-4">
                    <div data-delay="0" className="a-number-counter">
                        <div className="ac-number">25</div>
                        <span className="ac-sign"></span>
                        <div className="ac-title">Designers & Developers</div>
                    </div>
                    <span className="a-empty-space counter_empty_height"></span>
                    <div data-delay="0" className="a-number-counter">
                        <div className="ac-number">10</div>
                        <span className="ac-sign">+</span>
                        <div className="ac-title">Years of Experience</div>
                    </div>
                </div>
            </div>
        </div>
    );
}