import React, { useEffect, useState } from "react";
import { First } from "../Common/First";
import { Five } from "../Common/Five";
import { Four } from "../Common/Four";
import { Second } from "../Common/Second";
import { Six } from "../Common/Six";
import { Third } from "../Common/Third";
import webdesignbanner from "./../../images/pages/web_design_banner.webp";
import webdesigncontents from "./../../images/pages/web_design_contents.webp";
import webdesignmin from "./../../images/web_design-min.webp";
import "./../../css/style.css";
import "./../../css/inner.css";
import "./../../css/plugins.css";
import useScript from "./../useScript";
import { Backbutton } from "../Common/Backbutton";
export const WebsiteDesign = () => {
  useScript("./assets/js/scripts.js");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showBtn, setShowBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);
  return (
    <>
      {showBtn && <Backbutton />}
      <div id="page" data-barba="container">
        <div id="content" className="page-content">
          <First
            head1="Website design"
            head2="agency in London, UK."
            para="We design a website not because every other web design agency in London, UK is doing it. We do it to help you attract the right kind of audience at your website. Our designers and developers put their effort into shaping the most effective way for your customers to build an ongoing relationship with your company in London, UK."
            img={webdesignbanner}
          />
          <Second
            secondheading1="Our goal is to design website for you"
            secondheading2="That promotes your company 24/7"
            img={webdesigncontents}
          />
          <Third
            head4="No amount of work or money is enough for a website to become successful unless it guides visitors to the place where their desired information is. Our web designers and developers in London, UK can put different design elements together in a way that anyone who lands on your website instantly knows where to find what they're looking for."
            p1="A website is the center of a company's digital Eco-system wherein the experience of a user always matters. It is a truth that an average web design and development agency in London, UK may not be willing to accept or understand. But we do realise it because our commitment, as your trusted digital agency in London, UK, goes as far as it takes us to write a credible success story together with you."
            p2="If a website fails to set a positive impression of the business it's representing, it cannot be much of a brand asset. Since your company's got a story just like others in London, UK, you want / need to share it with the audience through website. Our creative designers and developers in London, UK adopt an interactive and immersive form of storytelling and implement it in your website efficiently. In cooperation with our thoughtful designers, they transform the old-school art of web interactivity into a captivating experience for each of your customers. In doing so, we follow the latest trends and standards as much as we deem necessary for your brand and agency."
            linkname="Start A Project Now"
          />

          <Four img={webdesignmin} />

          <Five
            h1="Are you looking for a responsive website design agency in London, UK? We design top-notch responsive websites that your business needs."
            p1="Tiger IT Solution's website designers in London, UK take a special approach to responsive website design and development, which we call a mobile-first initiative. There are more concerns about usability associated with mobile websites than their desktop versions. In addition, scaling up a mobile website requires less effort than doing the same to a desktop version. Responsive website designers can easily reevaluate the elements / sections that are functionally and visually necessary. However, website developers and designers at our agency take each step with a thorough understanding of the clients' needs."
            p2="We take note of a wide range of screen resolutions during our responsive website design and development stages. But we try to review the anticipated demographics of users first and then cater to the resolutions that are popular among your target audience. Any particular screen size that is not very common in London, UK today may be pretty popular someday. With this idea in mind, our responsive website designers and developers in London, UK craft and futureproof the user interface of a website that stands equally effective upon changed circumstances."
            p3="If there is one purpose that responsive website design serves best, it comes down to 'seamless user experience'. Not all web browsers do the job of rendering pages in the same ways all the time. This difference makes it obvious for our design agency to test our websites for compatibility with different web browsers. Our designers and developers in London, UK work closely with our knowledgeable QA professionals to make sure all design elements (based on styling properties) are supported by all browsers including the top ones like Google Chrome, Firefox, Safari, Edge, and Opera."
            linkname="Start A Project Now"
          />
          <Six link1="Start a Project" link2="Request for a Quotation" />
        </div>
      </div>
    </>
  );
};
