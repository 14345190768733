import React from "react";
import { Link } from "react-router-dom";
export const Header = () => {
  const pagerefresh = () => {
    window.location.pagerefresh();
  };
  return (
    <>
      <div className="site-header">
        <div className="header-wrapper">
          <div className="site-branding">
            <div className="site-logo">
              <Link to="/" onClick={pagerefresh}>
                <div className="waviy">
                  <span style={{ marginRight: "--i:1" }}>T</span>
                  <span style={{ marginRight: "--i:2" }}>I</span>
                  <span style={{ marginRight: "--i:3" }}>G</span>
                  <span style={{ marginRight: "--i:4" }}>E</span>
                  <span style={{ marginRight: "--i:5" }}>R</span>
                  <span style={{ width: "10px" }}></span>
                  <span style={{ marginRight: "--i:6" }}>I</span>
                  <span style={{ marginRight: "--i:7" }}>T</span>
                  <span style={{ width: "10px" }}></span>
                  <span style={{ marginRight: "--i:8" }}>S</span>
                  <span style={{ marginRight: "--i:9" }}>O</span>
                  <span style={{ marginRight: "--i:5" }}>L</span>
                  <span style={{ marginRight: "--i:6" }}>U</span>
                  <span style={{ marginRight: "--i:7" }}>T</span>
                  <span style={{ marginRight: "--i:8" }}>I</span>
                  <span style={{ marginRight: "--i:9" }}>O</span>
                  <span style={{ marginRight: "--i:5" }}>N</span>
                  <span style={{ marginRight: "--i:5" }}>S</span>
                </div>
              </Link>
            </div>
          </div>
          <div className="menu-toggle">
            <span className="toggle-line"></span>
            <span className="toggle-line"></span>
          </div>
          <div className="site-navigation">
            <span className="sub-back">
              <i className="icofont-long-arrow-left"></i>
            </span>
            <ul className="menu main-menu">
              <li className="menu-item">
                <Link to="/about-us" onClick={pagerefresh}>
                  About us
                </Link>
              </li>
              <li className="menu-item has-children">
                <Link to="#">Website Design</Link>
                <ul className="sub-menu">
                  <li className="menu-item">
                    <Link to="/web-design" onClick={pagerefresh}>
                      Website Design
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/corporate-web-design" onClick={pagerefresh}>
                      Corporate Website Design
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/responsive-web-design" onClick={pagerefresh}>
                      Responsive Website Design
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/wordpress-web-design" onClick={pagerefresh}>
                      WordPress Website Design
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/creative-web-design" onClick={pagerefresh}>
                      Creative Web Design
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/interactive-web-design" onClick={pagerefresh}>
                      Interactive Web Design
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link
                      to="/award-winning-website-design"
                      onClick={pagerefresh}
                    >
                      Award Winning Website Design
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/ecommerce-web-design" onClick={pagerefresh}>
                      Ecommerce Website Design
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="menu-item has-children">
                <Link to="#">Web Development </Link>
                <ul className="sub-menu">
                  <li className="menu-item">
                    <Link to="/web-development" onClick={pagerefresh}>
                      Web Development{" "}
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/corporate-web-development" onClick={pagerefresh}>
                      Corporate Web Development
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link
                      to="/responsive-web-development"
                      onClick={pagerefresh}
                    >
                      Responsive Web Development
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/wordpress-web-development" onClick={pagerefresh}>
                      WordPress Web Development
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/creative-web-development" onClick={pagerefresh}>
                      Creative Web Development
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link
                      to="/award-winning-website-development"
                      onClick={pagerefresh}
                    >
                      Award Winning Web Development
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/ecommerce-web-development" onClick={pagerefresh}>
                      Ecommerce Web Development
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/customphp-web-development" onClick={pagerefresh}>
                      Custom PHP Web Development
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="menu-item has-children">
                <Link to="#">Mobile Development </Link>
                <ul className="sub-menu">
                  <li className="menu-item">
                    <Link
                      to="/android-application-development"
                      onClick={pagerefresh}
                    >
                      Android Application Development
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link
                      to="/ios-application-development"
                      onClick={pagerefresh}
                    >
                      IOS Application Development
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link
                      to="/react-native-app-development"
                      onClick={pagerefresh}
                    >
                      React Native App Development
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="menu-item has-children">
                <Link to="#">Digital Marketing </Link>
                <ul className="sub-menu">
                  <li className="menu-item">
                    <Link to="/seo-smo" onClick={pagerefresh}>
                      SEO/SMO
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/sem-smm" onClick={pagerefresh}>
                      SEM/SMM
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/ppc" onClick={pagerefresh}>
                      PPC
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/content-marketing" onClick={pagerefresh}>
                      Content Marketing
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/email-marketing" onClick={pagerefresh}>
                      Email Marketing
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="menu-item">
                <Link to="/website-revamp" onClick={pagerefresh}>
                  Website Revamp
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/website-mentainance" onClick={pagerefresh}>
                  Website Maintenance
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/ecommerse-psg" onClick={pagerefresh}>
                  Ecommerce PSG
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/portfolio" onClick={pagerefresh}>
                  Portfolio
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/contact-us" onClick={pagerefresh}>
                  Get in Touch
                </Link>
              </li>
              <li className="menu-item">
                <a
                  href="https://buy.stripe.com/test_7sI15Hc9h6gc9Bm5kk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Payments
                </a>
              </li>
            </ul>
            <div className="menu-widget menu-widget-left">
              <ul className="social-list">
                <li>
                  <a
                    href="https://wa.me/+447405982708"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Whatsapp
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/people/Tiger-It-Solutions/100083026795745/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Facebook
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/tigeritsolutions01/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Instagram
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/solutions_tiger"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Twitter
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.pinterest.co.uk/tigeritsolutions/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Pinterest
                  </a>
                </li>
                <li>
                  <Link to="/blog" onClick={pagerefresh}>
                    Blogs
                  </Link>
                </li>
              </ul>
            </div>
            <div className="menu-widget menu-widget-right">
              <div className="git-button">
                <Link to="/blog" onClick={pagerefresh}>
                  Read our blog!
                </Link>
              </div>
            </div>
          </div>
          <div className="header-widgets">
            <div className="header-widget">
              <div className="header-cta-but">
                <Link
                  to-hover="Contact Us"
                  to="/contact-us"
                  onClick={pagerefresh}
                >
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="noise_effect"></div>
    </>
  );
};
