import React, { useEffect, useState } from "react";
import { Footer } from "../Footer";
import { Header } from "../Header";
import ukneedswebsitecover from "./../../images/blog_img/UK_needs_website_cover.webp";
import ukneedswebsitebody from "./../../images/blog_img/UK_needs_website_body.webp";
import { Link } from "react-router-dom";
import "./../../css/style.css";
import "./../../css/inner.css";
import "./../../css/plugins.css";
import useScript from "../useScript";
import { Backbutton } from "../Common/Backbutton";
export const Privacypolicy = () => {
  useScript("./assets/js/scripts.js");
  const pagerefresh = () => {
    window.location.pagerefresh();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showBtn, setShowBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);
  return (
    <>
      {showBtn && <Backbutton />}
      <Header />
      <div id="page" data-barba="container">
        <div id="content" className="page-content indexing">
          <div className="alioth-post">
            <div className="post-header">
              <div className="post-image">
                <img alt="Post Image" src={ukneedswebsitecover} />
              </div>
              <div className="post-details-wrapper wrapper-small">
                <div className="c-col-12">
                  <div className="post-metas single_date">
                  </div>
                  <div className="post-title">
                    <h1>
                      Privacy Policy
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="post-content wrapper-small no-margin">
              <div className="section">
                <div className="wrapper-small no-margin single_blog_contents">
                  <div className="c-col-12">
                    <div className="text-wrapper">
                    <div className="post-title">
                          <h4>
                          TIGER IT SOLUTION WEBSITE PRIVACY POLICY
                          </h4>
                        </div>
                        <p>
                        <b>TIGER IT SOLUTION </b>takes care about the protection of your information. We comply with the General Data Protection Regulation of the European Union (GDPR) and other laws protecting your information that we collect, use and store in our company.
                        </p>
                        <h4>1. Privacy Policy</h4>
                        <p>
                        Our Privacy Policy will answer the most important questions how we collect, use and store information about you. In case you have any questions, we are willing to help you as described below.<br/><br/>Please, carefully read our Privacy Policies:
                        </p>
                        <ul>
                          <li>Privacy Policy for Website Visitors</li>
                          <li>Cookie Policy</li>
                          <li>Privacy Policy for persons who submit inquiries, requests, complaints</li>
                          <li>Privacy Policy for Customers</li>
                          <li>Direct Marketing Privacy Policy</li>
                        </ul>
                        <h4>Last updated on: 2022/07/03</h4>
                        <p>
                        Qollorette takes care about the protection of your information. We comply with the General Data Protection Regulation of the European Union (GDPR) and other laws protecting your information that we collect, use and store in our company.
                        </p>
                        <h4>1. How should I read this Privacy Policy?</h4>
                        <p>
                        This Privacy Policy will answer the key questions how we collect, use and store information about you. In case you have any questions or any part of this Privacy Policy is unclear, we are ready to help you as described in Section 9 of this Privacy Policy. Please note that any reference to the word “we” in this Privacy Policy means our Company as indicated in Section 2 of the Privacy Policy.
                        </p>
                        <h4>2. Who is responsible for the protection of my information?</h4>
                        <p>
                        We are: <b>TIGER IT SOLUTION;</b><br/><br/>Identifier of our legal entity: 305711600;<br/><br/>Our address: A. Smetonos g. 23A-5, LT-35197 Panevėžys;<br/><br/>Tel. No. +44 7405 982708<br/><br/>E-mail: info@tigersolution.co.uk;
                        </p>
                        <h4>3. Why do you collect information about me?</h4>
                        <p>
                        We offer public access to the website <b>TIGER IT SOLUTION </b>(hereinafter – the Website), ensure its proper maintenance and improvement. For these reasons, cookies are used. More information about the cookies used on our website is available in the Cookie Policy.                        
                        </p>
                        <h4>4. What information do you collect, use and store about me?</h4>
                        <p>
                        We collect anonymous data about your browsing trends on our Website and your demographic data only from the cookies used on the Website while you are browsing.
                        </p>
                        <h4>5. What is the legal basis for collecting information about me?</h4>
                        <p>
                        We have a legitimate basis to collect information about you:
                        </p>
                        <ul>
                          <li>our legitimate interest to use the cookies mandatory for a proper functioning of the Website functions, i.e., functional cookies (Article 6(1)(f) of the GDPR);</li>
                          <li>your consent for the use of analytical cookies (Article 6(1)(a) of the GDPR).</li>
                          <li>your consent for the use of advertising cookies (Article 6(1)(a) of the GDPR).</li>
                        </ul>
                        <h4>6. Do you share my information with other entities?</h4>
                        <p>
                        We can share information with service providers who provide Website maintenance and support, cookie support, Website hosting services.                        
                        </p>
                        <h4>7. How long do you store information about me?</h4>
                        <p>
                        Cookie-related information is stored as detailed in the Cookie Policy.                        
                        </p>
                        <h4>8. What actions can I take concerning my information in your company?</h4>
                        <p>
                        In order to take any of the actions referred to below, please contact us as specified in Section 9. Please note that these rights are subject to statutory conditions and exceptions:                        
                        </p>
                        <ul>
                          <li>Right to request access to the information we have about you, if we process your personal data which enables your identification;</li>
                          <li>Right to request rectification of or supplementing the information held about you;</li>
                          <li>Right to request the erasure of data about you where:</li>
                          <li>Your data are processed unlawfully;</li>
                          <li>Your data are processed on the basis of our legitimate interest and you rebut our legitimate interest;</li>
                          <li>Your data are no longer necessary in relation to the purposes for which they were collected;</li>
                          <li>We have a legal obligation to erase information about you;</li>
                          <li>Right to request restriction on the processing of information held about you when you dispute data accuracy, object to the erasure of unlawfully processed data about you, or when you need the data for the establishment, exercise or defence of legal claims;</li>
                          <li>Right to object to the collection, use and storage of your information in our company where we rely on legitimate interest as a basis to process your personal data;</li>
                          <li>Right to request to export your data where you have provided data to us in a structured, commonly used and computer-readable format and given consent for the processing of such data or were we have to process such data for the performance of a contract/agreement with you;</li>
                          <li>Right to withdraw your consent at any time;</li>
                          <li>Right to submit a complaint to the State Data Protection Inspectorate (for more information, see vdai.lrv.lt).</li>
                        </ul>
                        <h4>9. How can you help me?</h4>
                        <p>
                        Should you have any questions, comments or complaints in relation to how we collect, use and store data about you, we are ready to help you. You may contact us by e-mail info@amzgroup.net.                        
                        </p>
                        <h4>10. Our contact details</h4>
                        <p>
                        If you have any questions, comments or complaints in relation to how we collect, use and store information about you, we are ready to help you. If you need any assistance, please contact us by e-mail info@amzgroup.net.                        
                        </p>
                        <h4>11. My rights</h4>
                        <p>
                        In order to take any of the actions referred to below, please contact us using the contact details indicated in section 2. Please note that these rights are subject to statutory conditions and exceptions:                        
                        </p>
                        <ul>
                          <li>Right to request access to the information held about you;</li>
                          <li>Right to request rectification of the information held about you;</li>
                          <li>Right to request erasure of the information held about you;</li>
                          <li>Right to request restriction of access to the information held about you or its erasure;</li>
                          <li>Right to object to the collection, use and storage of information about you in our company;</li>
                          <li>Right to request the exporting of your data or transfer them to another company;</li>
                          <li>Right to withdraw your consent at any time;</li>
                          <li>Right to submit a complaint to the State Data Protection Inspectorate (for more information, see vdai.lrv.lt)</li>
                        </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
