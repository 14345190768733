import React, { useEffect, useState } from "react";
import map from "./../images/icons/map.png";
import globe from "./../images/globe.webp";
import phone from "./../images/icons/phone.png";
import mail from "./../images/icons/mail.png";
import useScript from "./useScript";
import "./../css/style.css";
import "./../css/inner.css";
import { Backbutton } from "./Common/Backbutton";
export const Messagesend = () => {
  useScript("./assets/js/scripts.js");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showBtn, setShowBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);
  let mailtoHref = "mailto:info@tigersolution.co.uk";
  return (
    <>
      {showBtn && <Backbutton />}
      <div id="page" data-barba="container">
        <div id="content" className="page-content">
          <img className="contact_globe" src={globe} />
          <div className="wrapper contact_title_container indexing">
            <div className="c-col-1 hide_mobile"></div>
            <div className="c-col-10">
              <h1
                className="has-anim"
                data-stagger="0.1"
                data-duration="1"
                data-delay=".1"
                data-animation="linesUp"
              >
                Message sent
                <br />
                thank you.
              </h1>
            </div>
            <div className="c-col-2 hide_mobile"></div>
            <div className="c-col-10">
              <div className="contact_info_con">
                <div className="c-col-4">
                  <img alt="Contact Web Design Company" src={map} />
                  <p>
                    PIEDMONT RD. LONDON SE18 1TA
                    <br />
                    UK - SE18 1TA
                  </p>
                </div>
                <div className="c-col-4">
                  <img alt="Contact Web Design Agency" src={phone} />
                  <a
                    href="tel:+447405982708"
                    onclick="ga('send', 'event', { eventCategory: 'Contact', eventAction: 'Call', eventLabel: 'Mobile Button'});"
                  >
                    {" "}
                    <p>
                      Phone: +44 7405982708
                      <br />
                    </p>
                  </a>
                </div>
                <div className="c-col-4">
                  <img alt="Contact Web Development Company" src={mail} />
                  <a href={mailtoHref}>
                    <p>
                      Inquiries: info@tigersolution.co.uk <br />
                      Projects: info@tigersolution.co.uk{" "}
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div className="c-col-2 hide_mobile"></div>
          </div>
        </div>
      </div>
    </>
  );
};
