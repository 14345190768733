import React from "react";
import { Link } from "react-router-dom";

export const Five = (props) => {
  const pagerefresh = () => {
    window.location.pagerefresh();
  };

  return (
    <div className="section">
      <div className="wrapper indexing">
        <span
          className="a-empty-space hide_mobile"
          style={{ height: "150px" }}
        ></span>
        <span
          className="a-empty-space hide_desktop"
          style={{ height: "40px" }}
        ></span>
        <div className="c-col-12">
          <div className="text-wrapper">
            <h1
              className="has-anim small-title"
              data-stagger="0.01"
              data-duration="1.2"
              data-delay="0"
              data-animation="linesUp"
            >
              {props.h1}
            </h1>
          </div>
        </div>
        <div className="c-col-4 hide_mobile"></div>
        <div className="c-col-8">
          <div className="text-wrapper">
            <p
              style={{ color: "rgba(255, 255, 255, .7)" }}
              className="has-anim"
              data-stagger="0.05"
              data-duration="1.25"
              data-delay="1"
              data-animation="linesUp"
            >
              {props.p1}
            </p>
            <p
              style={{ color: "rgba(255, 255, 255, .7)" }}
              className="has-anim"
              data-stagger="0.1"
              data-duration="1.25"
              data-delay="1"
              data-animation="linesUp"
            >
              {props.p2}
            </p>
            <p
              style={{ color: "rgba(255, 255, 255, .7)" }}
              className="has-anim"
              data-stagger="0.15"
              data-duration="1.25"
              data-delay="1"
              data-animation="linesUp"
            >
              {props.p3}
            </p>
          </div>
          <span className="a-empty-space" style={{ height: "30px" }}></span>
          <span
            data-color="hsla(0,0%,100%,.4)"
            data-anim="true"
            className="alioth-seperator"
          ></span>
          <span className="a-empty-space" style={{ height: "30px" }}></span>

          <div className="a-button style_1">
            <span
              className="button-overlay"
              style={{ top: "1467px", left: "27px", height: "0%", width: "0%" }}
            ></span>
            <Link to="/contact-us" onClick={pagerefresh}>
              {props.linkname}
            </Link>
            <i className="icofont-arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
  );
};
