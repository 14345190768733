import React, { useEffect, useState } from "react";
import { First } from "../Common/First";
import { Second } from "../Common/Second";
import { Third } from "../Common/Third";
import { Four } from "../Common/Four";
import { Five } from "../Common/Five";
import { Six } from "../Common/Six";
import corporateweb from "./../../images/pages/corporate_web.webp";
import corporatewebdevelopment from "./../../images/pages/corporate_web_development.webp";
import corporatewebdevelopmentcompany from "./../../images/corporate_web_development_company.webp";
import "./../../css/style.css";
import "./../../css/inner.css";
import "./../../css/plugins.css";
import useScript from "./../useScript";
import { Backbutton } from "../Common/Backbutton";
export const CorporateWebDevelopment = () => {
  useScript("./assets/js/scripts.js");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showBtn, setShowBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);
  return (
    <>
      {showBtn && <Backbutton />}
      <div id="page" data-barba="container">
        <div id="content" className="page-content">
          <First
            head1="Corporate"
            head2="web development."
            para="As a web design and development company at Tiger It, each corporate website is custom-designed and developed to meet the specific needs of the client's company in London, UK. A sophisticated website with rich branding helps your corporate company stand out among competitors. Navigations and CTAs are always at the forefront of our design and development process, and our company focuses on developing websites that produce results. Our designers and developers are always concerned with navigation ease and call to action in our design process. Growing result-oriented websites are what we aim for."
            img={corporateweb}
          />
          <Second
            secondheading1="Corporates are all about credibility, value, and goodwill"
            secondheading2="We develop websites that offer all of these and more"
            img={corporatewebdevelopment}
          />
          <Third
            head4="At Tiger IT Solutions our web designers and developers in London, UK help brands stay ahead of the ever-changing digital landscape with customer-centric websites that create tangible business results. We combine our extensive experience with the web with your company's design requirements and development ideas to create a unique website for your company. Our unique corporate website design and development service helps spread your brand's message across London, UK."
            p1=" A reliable way to communicate among companies, stakeholders, partners, etc., is fundamental to many of our corporate clients in London, UK. As a result, some web development companies do not give importance to the appearance of their websites. Tiger It believes in making the website function well without worrying about an unprofessional-looking front-end impression. "
            p2="We design and develop corporate websites so that the elements work as a whole to present the company's identity uniquely and to remind the audience of the brand when they see it anywhere on the website. In London, UK, we try to understand your customers' needs, so our designers and developers can create work representative of them."
            linkname="Start A Project Now"
          />
          <Four img={corporatewebdevelopmentcompany} />
          <Five
            h1="Are you looking for a corporate web development company in London, UK? We develop corporate websites that your business needs"
            p1=" Tiger IT Solutions serves as a leading web development company in London, UK that provides experienced consultants, designers, and developers to help you take your online presence to the next level in London, UK. Our web developers and designers specialize in the design and development of corporate websites. You can count on us to provide high-performance, feature-rich, and digitally transformative web experiences that are user-friendly, fully functional, highly secure, and scalable to your business needs. "
            p2="Additionally, Tiger IT Solutions can help your company with migrations, upgrades, and integrations and create a robust, scalable, and secure enterprise web experience. As part of our managed services offering, we provide 24x7 support and maintenance to handle even the most complex and extreme situations quickly and efficiently in London, UK. "
            p3="The Tiger IT Solutions team in London, UK offers a wide range of website design and development services, from designing mobile web applications and responsive websites to building custom e-commerce and intranet experiences using the latest web technologies. Consumers in London, UK evaluate their online experience when making decisions, with one-third of them visiting websites at least once before purchasing. So, it's essential to ensure that your website is visually appealing, usable, and accessible to a broad group of audience."
            linkname="Start A Project Now"
          />
          <Six link1="Start a Project" link2="Request for a Quotation" />
        </div>
      </div>
    </>
  );
};
