import React, { useEffect, useState } from "react";
import { First } from "../Common/First";
import { Five } from "../Common/Five";
import { Four } from "../Common/Four";
import { Second } from "../Common/Second";
import { Six } from "../Common/Six";
import { Third } from "../Common/Third";
import responsivewebsitedesign from "./../../images/pages/responsive_website_design.webp";
import responsivewebsitedesigncompany from "./../../images/pages/responsive_website_design_company.webp";
import responsivewebsitemin from "./../../images/responsive_website-min.webp";
import "./../../css/style.css";
import "./../../css/inner.css";
import "./../../css/plugins.css";
import useScript from "./../useScript";
import { Backbutton } from "../Common/Backbutton";
export const ResponsiveWebsiteDesign = () => {
  useScript("./assets/js/scripts.js");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showBtn, setShowBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);
  return (
    <>
      {showBtn && <Backbutton />}
      <div id="page" data-barba="container">
        <div id="content" className="page-content">
          <First
            head1="Responsive"
            head2="website design."
            para="Doing business in London, UK is as profitable as it is challenging. You cannot risk paying for a separate version of your company website designed for every kind of device instead of designing a responsive website. Again, you never know which particular devices your visitors may use. So, you need a responsive website that is ready to load properly on all devices."
            img={responsivewebsitedesign}
          />
          <Second
            secondheading1="Making everything fit isn't all about responsive design"
            secondheading2="We design responsive websites that adapts to all devices"
            img={responsivewebsitedesigncompany}
          />
          <Third
            head4="In London, UK and four in every five people use at least one mobile phone, most of who are supposed to visit a company's website using their phones. The significance of responsive website design and development is therefore real, making the job of a website design agency even more crucial. Their design skills are of great importance for responsive website design and development."
            p1="These days, laptops are getting more popular than desktops, and smartphones are getting more traction with London, UKans than laptops are. None of these types can be left out of the equation since user experience is the key to success in anything you do online. Responsive design can be an all-encompassing solution, but there is more to the experience of your web traffic than just designing a responsive website that fits all screen sizes."
            p2="Assuming that your company website looks okay, no matter where it is accessed from. It could be a great relief for you and the web design agency that developed it, but unfortunately, it is NOT. The purpose of responsive website design is not just to let visitors view the website properly without having to pan, zoom, or scroll unnecessarily. The designers building your responsive website must ensure that every single element on your web pages looks and works exactly how they should, neither bigger/ smaller nor inaccessible, regardless of the size of the screen displaying them. So, the responsive website design agency in London, UK that you're going to hire should possess the required skill set."
            linkname="Start A Project Now"
          />

          <Four img={responsivewebsitemin} />

          <Five
            h1="Are you looking for a responsive website design agency in London, UK? We design a top-notch responsive websites that your business needs."
            p1="Tiger IT solutions website designers in London, UK take a special approach to responsive website design and development, which we call a mobile-first initiative. There are more concerns about usability associated with mobile websites than their desktop versions. In addition, scaling up a mobile website requires less effort than doing the same to a desktop version. Responsive website designers can easily reevaluate the elements/sections that are functionally and visually necessary. However, website developers and designers at our agency take each step with a thorough understanding of the client's needs."
            p2="We take note of a wide range of screen resolutions during our responsive website design and development stages. But we try to review the anticipated demographics of users first and then cater to the resolutions that are popular among your target audience. Any particular screen size that is not very common in London, UK today may be pretty popular someday. With this idea in mind, our responsive website designers and developers in London, UK craft and future proof the user interface of a website that stands equally effective upon changed circumstances."
            p3="If there is one purpose that responsive website design serves best, it comes down to a 'seamless user experience. Not all web browsers do the job of rendering pages, in the same way, all the time. This difference makes it obvious for our design agency to test our websites for compatibility with different web browsers. Our designers and developers in London, UK work closely with our knowledgeable QA professionals to make sure all design elements (based on styling properties) are supported by all browsers including the top ones like Google Chrome, Firefox, Safari, Edge, and Opera.
                    "
            linkname="Start A Project Now"
          />
          <Six link1="Start a Project" link2="Request for a Quotation" />
        </div>
      </div>
    </>
  );
};
