import React, { useEffect, useState } from "react";
import { First } from "../Common/First";
import { Five } from "../Common/Five";
import { Four } from "../Common/Four";
import { Second } from "../Common/Second";
import { Six } from "../Common/Six";
import { Third } from "../Common/Third";
import creativewebdevelopment from "./../../images/pages/creative_web_development.webp";
import creativeweb from "./../../images/pages/creative_web.webp";
import creativewebdevelopmentcompany from "./../../images/creative_web_development_company.webp";
import "./../../css/style.css";
import "./../../css/inner.css";
import "./../../css/plugins.css";
import useScript from "./../useScript";
import { Backbutton } from "../Common/Backbutton";
export const CreativeWebDevelopment = () => {
  useScript("./assets/js/scripts.js");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showBtn, setShowBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);
  return (
    <>
      {showBtn && <Backbutton />}
      <div id="page" data-barba="container">
        <div id="content" className="page-content">
          <First
            head1="Creative"
            head2="web development."
            para="The goal of a creative website is to create a user experience that is appealing to your audience and incorporate high-tech hardware to provide your audience with an engaging experience. Tiger IT Solutions specialises in designing visually appealing websites while making them user-friendly and memorable. Our clients in London, UK have always received this support from our creative web developers."
            img={creativewebdevelopment}
          />
          <Second
            secondheading1="Creative web development is not just about creativity"
            secondheading2="It's about developing a website to tell your story uniquely"
            img={creativeweb}
          />
          <Third
            head4="Our creative web development company offers top-class web solutions to our clients across London, UK and beyond. As a result-driven creative agency specializing in web design, web development, and digital marketing services, we have helped start-ups, small businesses, and some of the biggest businesses in London, UK to succeed online."
            p1="We develop creative websites from scratch or revamp old ones, allowing clients to select what is best for their companies in London, UK. Apart from web design and development, we provide maintenance services to help the client keep up a website at its best. We specialise in search engine-friendly, creative website design that is easy for website visitors to navigate and implement responsive design principles to develop a website that works well on all devices. Our creative web development company in London, UK focuses on user experience, no matter how hard it is to ensure."
            p2="When you count on the right web development company in London, UK, you need not worry about ensuring that user experience gets to the next level and brings more leads. An integral part of what we do as a creative web design agency in London, UK is to ensure that audiences are directed to what they want to see. Therefore, we can create more helpful content for our clientele in London, UK. Our focus is to provide our clients with a smooth and interactive browsing journey when the visitor first enters a website. With an easy navigation functionality and engaging user interface, the clients are more likely to spend time on the website longer."
            linkname="Start A Project Now"
          />

          <Four img={creativewebdevelopmentcompany} />

          <Five
            h1="Are you looking for a creative web development company in London, UK? We develop creative websites that your business needs."
            p1="Our team of creative developers and designers has developed websites for businesses in London, UK's wide range of industries. Most likely, your company falls into one of the business categories we've served before. We'll do our utmost to ensure that your website is interactive and creative. As a creative web development company in London, UK, it's our obligation to meet the clients' expectations in every way possible."
            p2="Tiger IT Solutions in London, UK firmly believes in providing a creative and engaging design for the new website with a better responsive design for a more user-friendly, affordable experience. We incorporate better streamlining and user experiences leading to more time on the website, encouraging your visitors to explore your products and services and ultimately purchase."
            p3="Our expertise extends beyond creating creative and interactive websites and executing lead-generating digital marketing campaigns to provide you with the technology and equipment to host your site locally in London, UK. As experts in digital marketing, we plan and manage campaigns efficiently in London, UK too. Our success has helped our customers gain leads that helped them convert into sales over the years. Feel free to ask any questions you may have; our expert website designers and developers will never leave your side!"
            linkname="Start A Project Now"
          />
          <Six link1="Start a Project" link2="Request for a Quotation" />
        </div>
      </div>
    </>
  );
};
