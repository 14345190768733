import React, { useEffect, useState } from "react";
import { First } from "../Common/First";
import { Five } from "../Common/Five";
import { Four } from "../Common/Four";
import { Second } from "../Common/Second";
import { Six } from "../Common/Six";
import { Third } from "../Common/Third";
import "./../../css/style.css";
import "./../../css/inner.css";
import "./../../css/plugins.css";
import useScript from "./../useScript";
import wordpressweb from "./../../images/pages/wordpress_web.webp";
import wordpresswebdevelopment from "./../../images/pages/wordpress_web_development.webp";
import wordpresswebcompanyuk from "./../../images/wordpress_web_company_UK.webp";
import { Backbutton } from "../Common/Backbutton";
export const WordPressWebDevelopment = () => {
  useScript("./assets/js/scripts.js");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showBtn, setShowBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);
  return (
    <>
      {showBtn && <Backbutton />}
      <div id="page" data-barba="container">
        <div id="content" className="page-content">
          <First
            head1="WordPress"
            head2="web development."
            para="WordPress is the perfect platform for small businesses in London, UK to boost their online presence without investing many resources and time into it. Having a quality WordPress website can drive more business for you even if you do not want to spend more like big companies. As a leading WordPress development company in London, UK, Tiger IT Solutions specializes in building responsive websites with this open-source website creation platform."
            img={wordpressweb}
          />
          <Second
            secondheading1="A good Website not only a benefit to business"
            secondheading2="but also helps your company grow even faster"
            img={wordpresswebdevelopment}
          />
          <Third
            head4="Our WordPress website designers and developers are passionate about building high-performance, custom WordPress websites as a one-stop WordPress web design and development company in London, UK. Sound knowledge of WordPress web, theme, and plugin development enables them to develop an effective website that is tailored to your business needs. Our WordPress website designers create seamless, scalable, robust, and highly secure WordPress websites applying all the latest WordPress updates."
            p1="We incorporate custom plugins and APIs into our WordPress website development services for enhanced functionality that allows for better user experience, higher engagement, and conversion in London, UK. As long as WordPress is built with standard development and design practices, we can harness whatever it offers. Moreover, the UI UX designers use their experience to figure out and incorporate what your specific group of London, UK customers like and desire on your website."
            p2="We have a team of highly experienced WordPress expert designers and developers who are familiar with all the latest WordPress versions and keep up-to-date on the latest upgrades and initiatives. To provide our clients in London, UK with a competitive edge in the market, we offer upgrade services that encompass all improvements and initiatives on your website. We provide feature-rich, scalable, and the most efficient WordPress development solutions for our clients in London, UK and beyond."
            linkname="Start A Project Now"
          />

          <Four img={wordpresswebcompanyuk} />

          <Five
            h1="Are you looking for a WordPress web development company in London, UK? We develop stunning WordPress websites that your business needs."
            p1="We are confident that our existing and potential clients in London, UK can anticipate a successful conclusion to their WordPress projects. Our UI designers and WP developers can help you with anything in a highly collaborative environment. Tiger IT Solutions in London, UK is a dedicated web development company that creates dynamic and professional websites according to the client's specifications and ideology and meets market standards to remain competitive."
            p2="Our services in London, UK range from fundamental to advanced customisation, theme and plugin development, PSD to WP conversion, WooCommerce, and blogging solutions. Enterprises, small businesses, government organizations, and startups in London, UK interested in building highly customizable WordPress websites or migrating their existing website can benefit from our world-class WordPress web development services."
            p3="In addition to developing CRM applications, our WordPress website developers in London, UK also have experience customizing and integrating CRM applications with e-commerce websites without affecting the design or overall functionality of the website. So, suppose you are interested in making your website more appealing and engaging using an easy-to-use CMS. In that case, Tiger IT Solutions is the right web development company you are looking for. Feel free to make a call if you need any further assistance."
            linkname="Start A Project Now"
          />
          <Six link1="Start a Project" link2="Request for a Quotation" />
        </div>
      </div>
    </>
  );
};
