import React from "react";
import one from "./../../images/projects/1.webp";
import two from "./../../images/projects/2.webp";
import three from "./../../images/projects/3.webp";
import four from "./../../images/projects/4.webp";
import five from "./../../images/projects/5.webp";
import six from "./../../images/projects/6.webp";
import seven from "./../../images/projects/7.webp";
import eight from "./../../images/projects/img-12.webp";
import nine from "./../../images/projects/9.webp";
import ten from "./../../images/projects/10.webp";
import eleven from "./../../images/projects/img-11.webp";
import twelve from "./../../images/projects/12.webp";
// import one from "./../../images/projects/flickontechnology.webp"
// import two from "./../../images/projects/BBQMOMMENT.webp"
// import three from "./../../images/projects/QOLLORETTE.webp";
// import four from "./../../images/projects/MAXBOND.webp"
// import five from "./../../images/projects/WINAWIN.webp"
// import six from "./../../images/projects/MOTTIWAY.webp"
// import seven from "./../../images/projects/7.webp"
// import eight from "./../../images/projects/tavo_pokycio_gidas.webp"
// import nine from "./../../images/projects/SOMORE.webp"
// import ten from "./../../images/projects/EXITMAKERS.webp"
// import eleven from "./../../images/projects/SHOPAY.webp"
// import twelve from "./../../images/projects/OCTALOOP.webp"
import { Link } from "react-router-dom";
export const HomeThirdPortfolio = () => {
  const pagerefresh = () => {
    window.location.pagerefresh();
  };
  return (
    <div className="section home_portfolio_section hide_mobile">
      <div className="wrapper-full no-margin indexing">
        <div className="c-col-12 no-gap">
          <div
            className="portfolio-showcase showcase-wall"
            data-barba-namespace="sc-wall"
          >
            <div className="wall-projects">
              <div className="wall-projects-top">
                <div className="wall-project">
                  <Link to="/portfolio" onClick={pagerefresh}>
                    <div className="project-title">Avantist</div>
                  </Link>
                  <div className="project-image">
                    <div
                      className="showcase-video"
                      data-plyr-provider="vimeo"
                      data-plyr-embed-id="698845713"
                    ></div>
                  </div>
                </div>
                <div className="wall-project">
                  <Link to="/portfolio" onClick={pagerefresh}>
                    <div className="project-title">Flickon Technology </div>
                  </Link>
                  <div className="project-image">
                    <img alt="Maison De L'Asie" src={one} />
                  </div>
                </div>
                <div className="wall-project">
                  <Link to="/portfolio" onClick={pagerefresh}>
                    <div className="project-title">BBQ Momment</div>
                  </Link>
                  <div className="project-image">
                    <img alt="Second Look" src={two} />
                  </div>
                </div>
                <div className="wall-project">
                  <Link to="/portfolio" onClick={pagerefresh}>
                    <div className="project-title">Qollorette</div>
                  </Link>
                  <div className="project-image">
                    <img alt="OTO Wellness" src={three} />
                  </div>
                </div>
                <div className="wall-project">
                  <Link to="/portfolio" onClick={pagerefresh}>
                    <div className="project-title">Maxbond</div>
                  </Link>
                  <div className="project-image">
                    <img alt="PappaRich" src={four} />
                  </div>
                </div>
              </div>
              <div className="wall-projects-bottom">
                <div className="wall-project">
                  <Link to="/portfolio" onClick={pagerefresh}>
                    <div className="project-title">Winawin</div>
                  </Link>
                  <div className="project-image">
                    <img alt="Reputation Defender" src={five} />
                  </div>
                </div>
                <div className="wall-project">
                  <Link to="/portfolio" onClick={pagerefresh}>
                    <div className="project-title">iNOVA Jewellery</div>
                  </Link>
                  <div className="project-image">
                    <img alt="iNOVA Jewellery" src={five} />
                  </div>
                </div>
                <div className="wall-project">
                  <Link to="/portfolio" onClick={pagerefresh}>
                    <div className="project-title">Mottiway</div>
                  </Link>
                  <div className="project-image">
                    <img alt="Telescope Studio" src={six} />
                  </div>
                </div>
                <div className="wall-project">
                  <Link to="/portfolio" onClick={pagerefresh}>
                    <div className="project-title">Skaitmeninis Verslas</div>
                  </Link>
                  <div className="project-image">
                    <img alt="PrintLab" src={seven} />
                  </div>
                </div>
                <div className="wall-project">
                  <Link to="/portfolio" onClick={pagerefresh}>
                    <div className="project-title">Tavo pokyciu gidas</div>
                  </Link>
                  <div className="project-image">
                    <img alt="United Lifestyle" src={eight} />
                  </div>
                </div>
                <div className="wall-project">
                  <Link to="/portfolio" onClick={pagerefresh}>
                    <div className="project-title">Somore</div>
                  </Link>
                  <div className="project-image">
                    <img alt="United Lifestyle" src={nine} />
                  </div>
                </div>
                <div className="wall-project">
                  <Link to="/portfolio" onClick={pagerefresh}>
                    <div className="project-title">Exit Makers</div>
                  </Link>
                  <div className="project-image">
                    <img alt="United Lifestyle" src={ten} />
                  </div>
                </div>
                <div className="wall-project">
                  <Link to="/portfolio" onClick={pagerefresh}>
                    <div className="project-title">Shopay</div>
                  </Link>
                  <div className="project-image">
                    <img alt="United Lifestyle" src={eleven} />
                  </div>
                </div>
                <div className="wall-project">
                  <Link to="/portfolio" onClick={pagerefresh}>
                    <div className="project-title">Exit Makers</div>
                  </Link>
                  <div className="project-image">
                    <img alt="United Lifestyle" src={twelve} />
                  </div>
                </div>
              </div>
            </div>
            <div className="wall-images"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
