import React, { useEffect, useState } from "react";
import globe from "./../images/globe.webp";
import map from "./../images/icons/map.png";
import phone from "./../images/icons/phone.png";
import mail from "./../images/icons/mail.png";
import useScript from "./useScript";
import "./../css/style.css";
import "./../css/inner.css";
import { Link, useNavigate } from "react-router-dom";
import { Backbutton } from "./Common/Backbutton";
export const ContactUs = () => {
  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  const navigate = useNavigate();
  useScript("./assets/js/scripts.js");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showBtn, setShowBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);
  let mailtoHref = "mailto:info@tigersolution.co.uk";
  const [userName, setUserName] = useState("");
  const [userEmail, setuserEmail] = useState("");
  const [userPhone, setuserPhone] = useState("");
  const [userMessage, setuserMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("none");
  const [bustatus, setbustatus] = useState(true);
  const [message, setMessage] = useState("none");
  const [showErrorMsg, setshowErrorMsg] = useState("none");
  const [issubmitting, setIssubmitting] = useState(false);
  const [errors, setErrors] = useState({
    userName: "",
    userEmail: "",
    userMessage: "",
    userPhone: "",
  });
  const textChange = (event) => {
    setuserEmail(event.target.value);
    if (event.target.value == "") {
      setbustatus(true);
    } else {
      setbustatus(false);
    }
  };
  const submitForm = (e) => {
    e.preventDefault();
    // window.location.pagerefresh();
    setIssubmitting(true);
    setIsError(false);
    setshowErrorMsg("none");
    setErrors({
      userName: "",
      userEmail: "",
      userMessage: "",
      userPhone: "",
    });
    let flag = 0;
    const regex = RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
    if (userName === "") {
      setErrors((prev) => ({ ...prev, userName: "Name required" }));
      setIsError(true);
      flag = 1;
    }
    if (userEmail === "") {
      setErrors((prev) => ({ ...prev, userEmail: "Email required" }));
      setIsError(true);
      flag = 1;
    } else if (!regex.test(userEmail)) {
      setErrors((prev) => ({ ...prev, userEmail: "Email address is invalid" }));
      setIsError(true);
      flag = 1;
    }
    if (userPhone.trim() === "") {
      setErrors((prev) => ({ ...prev, userPhone: "Mobile required" }));
      setIsError(true);
      flag = 1;
    } else if (isNaN(+userPhone)) {
      setErrors((prev) => ({ ...prev, userPhone: "Mobile is invalid" }));
      setIsError(true);
      flag = 1;
    } else if (userPhone.length < 10) {
      setErrors((prev) => ({
        ...prev,
        userPhone: "Mobile needs to be ten digit",
      }));
      setIsError(true);
      flag = 1;
    }
    if (userMessage === "") {
      setErrors((prev) => ({ ...prev, userMessage: "Place required" }));
      setIsError(true);
      flag = 1;
    }
    if (flag === 0) {
      const formData = new FormData();
      formData.append("useremail", userEmail);
      formData.append("userName", userName);
      formData.append("message", userMessage);
      formData.append("phone", userPhone);
      const data = {
        useremail: userEmail,
        userName: userName,
        message: userMessage,
        phone: userPhone,
      };
      console.log("register data", data);
      try {
        fetch("https://new.tigersolution.co.uk/sendEmail/api/contact_us", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((response) => {
            console.log("response post", response);

            if (response.status === true) {
              console.log("response true", response);
              localStorage.clear();
              localStorage.setItem("userEmail", userEmail.toLocaleLowerCase());
              localStorage.setItem("userName", userName);
              localStorage.setItem("userPhone", userPhone);
              localStorage.setItem("userMessage", userMessage);
              navigate("/messagesent", { replace: true });
              window.location.reload();
            } else {
              setshowErrorMsg("block");
              setMessage(response.message);
            }
          });
      } catch (ex) {
        console.log("in catch", ex.code);
        console.log(ex);
        setshowErrorMsg("block");
        setMessage("Please fill all the fields");
      }
    } else {
      return false;
    }
  };
  return (
    <>
      {showBtn && <Backbutton />}
      <div id="page" data-barba="container">
        <div id="content" className="page-content">
          <div id="page" data-barba="container">
            <div id="content" className="page-content">
              <img className="contact_globe" src={globe} />
              <div className="wrapper contact_title_container indexing">
                <div className="c-col-1 hide_mobile"></div>
                <div className="c-col-10">
                  <h1
                    className="has-anim"
                    data-stagger="0.1"
                    data-duration="1"
                    data-delay=".1"
                    data-animation="linesUp"
                  >
                    We'd love to
                    <br />
                    hear from you.
                  </h1>
                </div>
                <div className="c-col-2 hide_mobile"></div>
                <div className="c-col-10">
                  <div className="contact_info_con">
                    <div className="c-col-4">
                      <img alt="Contact Web Design Company" src={map} />
                      <p>
                        Piedmont rd.
                        <br />
                        London, UK - SE18 1TA
                      </p>
                    </div>
                    <div className="c-col-4">
                      <img alt="Contact Web Design Agency" src={phone} />
                      <a
                        href="tel:+447405982708"
                        onClick="ga('send', 'event', { eventCategory: 'Contact', eventAction: 'Call', eventLabel: 'Mobile Button'});"
                      >
                        {" "}
                        <p>
                          Phone: +44 7405982708
                          <br />
                        </p>
                      </a>
                    </div>
                    <div className="c-col-4">
                      <img alt="Contact Web Development Company" src={mail} />
                      <a href={mailtoHref}>
                        <p>
                          Inquiries: info@tigersolution.co.uk <br />
                          Projects: info@tigersolution.co.uk{" "}
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="c-col-2 hide_mobile"></div>

                <div className="c-col-10">
                  <div className="c-col-12">
                    <div className="text-wrapper">
                      <h4
                        className="has-anim"
                        data-stagger="0.1"
                        data-duration="1"
                        data-delay=".5"
                        data-animation="linesUp"
                      >
                        Whether you're looking to discuss a new project or
                        simply say hello, <br />
                        we'd love to hear from you.
                      </h4>
                    </div>
                  </div>
                  {/* <form method="post" name="myForm" >*/}
                  <div className="form">
                    <div className="c-col-4 name_con">
                      <input
                        type="text"
                        name="name"
                        placeholder="Enter Name *"
                      />
                    </div>
                    <div className="c-col-4">
                      <input
                        type="text"
                        name="your_name"
                        placeholder="Your Name *"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                      {errors.userName && (
                        <span style={{ color: "red" }}>{errors.userName}</span>
                      )}
                    </div>
                    <div className="c-col-4">
                      <input
                        type="email"
                        name="your_email"
                        placeholder="Your Email *"
                        onChange={textChange}
                      />
                      {errors.userEmail && (
                        <span style={{ color: "red" }}>{errors.userEmail}</span>
                      )}
                    </div>
                    <div className="c-col-4">
                      <input
                        type="tel"
                        onKeyDown={(e) =>
                          exceptThisSymbols.includes(e.key) &&
                          e.preventDefault()
                        }
                        name="your_phone"
                        placeholder="Your Phone"
                        value={userPhone}
                        onChange={(e) => setuserPhone(e.target.value)}
                      />
                      {errors.userPhone && (
                        <span style={{ color: "red" }}>{errors.userPhone}</span>
                      )}
                    </div>
                    <div className="c-col-12">
                      <textarea
                        name="your_message"
                        placeholder="Enter your message here *"
                        value={userMessage}
                        onChange={(e) => setuserMessage(e.target.value)}
                      ></textarea>
                      {errors.userMessage && (
                        <span style={{ color: "red" }}>
                          {errors.userMessage}
                        </span>
                      )}
                    </div>

                    <div className="c-col-4">
                      <button className="submit_button" onClick={submitForm}>
                        Send Message
                      </button>
                      <span style={{ display: showErrorMsg, color: "red" }}>
                        {message}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
