import React from "react";

export const UserReview = ({ image }) => {
  return (
    <div className="testimonials-review">
      <div className="testimonials-img-ctn">
        <img className="testimonials-img" src={image} alt="User-Img" />
      </div>
      <h3>Shirley Fultz</h3>
      <div className="">
        <p className="testimonial-comment">
          It's freeing to be able to catch up on customized news and not be
          distracted by a social media element on the same site
        </p>
      </div>
    </div>
  );
};
