import React from "react";
import { HomeThirdPortfolio } from "./HomeThirdPortfolio";
import { HomeFirstVideo } from "./HomeFirstVideo";
import { HomeSecondSec } from "./HomeSecondSec";
import { HomeFourSec } from "./HomeFourSec";
import { HomeFiveSec } from "./HomeFiveSec";
import { HomeSixSec } from "./HomeSixSec";
import { Testimonials } from "./Testimonials/Testimonials";
import "./../../css/style.css";
import "./../../css/home.css";
import "./../../css/plugins.css";
import useScript from "./../useScript";
import { HomeSevenSec } from "./HomeSevenSec";

export const Home = () => {
  useScript("./assets/js/scripts.js");
  return (
    <>
      {/* <Backbutton/> */}
      <div id="page" data-barba="container">
        <div id="content" className="page-content">
          <HomeFirstVideo />
          <HomeSecondSec />
          <HomeThirdPortfolio />
          <HomeFourSec />
          <Testimonials />
          <HomeFiveSec />
          <HomeSixSec />
          <HomeSevenSec />
        </div>
      </div>
    </>
  );
};
