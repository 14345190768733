import React from "react";
import singapore_airline from "./../../images/client/singapore_airline.png";
import singtel from "./../../images/client/singtel.png";
import nus from "./../../images/client/nus.png";
import standard from "./../../images/client/standard-chartered.png";
import samsung from "./../../images/client/samsung.png";
import sysmex from "./../../images/client/sysmex.png";
export const HomeFiveSec = () => {
  return (
    <div className="wrapper no-gap no-margin hide_mobile">
      <div className="c-col-12 indexing">
        <div data-anim="true" className="alioth-clients column-6">
          <div className="a-client">
            <img
              className="initial_display"
              alt="London, UK Airline Web Development Client"
              src={singapore_airline}
            />
          </div>
          <div className="a-client">
            <img
              className="initial_display"
              alt="Singtel Web Design Client"
              src={singtel}
            />
          </div>
          <div className="a-client">
            <img
              className="initial_display"
              alt="NUS Web Development Client"
              src={nus}
            />
          </div>
          <div className="a-client">
            <img
              className="initial_display"
              alt="Standard Chartered Web Design Client"
              src={standard}
            />
          </div>
          <div className="a-client">
            <img
              className="initial_display"
              alt="Samsung Web Development Client"
              src={samsung}
            />
          </div>
          <div className="a-client">
            <img
              className="initial_display"
              alt="Sysmex Web Design Client"
              src={sysmex}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
