import React from "react";
import { Link } from "react-router-dom";
export const Backbutton = () => {
    const pagerefresh = () =>{
        window.location.pagerefresh();
    }
    return (
        <div className="backbtun-fix">
            <div className="ftr-cta-but">
                <Link data-hover="Back" to="/" onClick={pagerefresh}>
                    Back
                </Link>
            </div>
        </div>
    );
}