import React from "react";
import video from "./../../videos/home.mp4";
export const HomeFirstVideo = () => {
  return (
    <div className="section no-margin">
      <div className="wrapper-full no-gap no-margin">
        <div className="c-col-12 no-gap no-margin">
          <div data-animate="true" className="alioth-personal-head">
            <video
              playsInline
              autoPlay
              muted
              loop
              poster="videos/poster.webp"
              id="bgvid"
            >
              <source src={video} type="video/mp4" />
            </video>
            <div className="video_content">
              <div className="aph-name">
                <span className="name-front">websites</span>
              </div>
              <div className="aph-details">
                <div className="aph-welc">We turn ideas into</div>
                <div className="aph-sub-text">
                  Established in the year 2016, Tiger IT Solutions is a leading
                  web design & development company in London, UK delivering
                  creative and award-wining websites for years.
                </div>
              </div>
              <a className="circular-button scroller" href="#secondSec">
                <span>
                  Learn More<i className="icofont-arrow-down"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
