import React, { useEffect, useState } from "react";
import { First } from "../Common/First";
import { Five } from "../Common/Five";
import { Four } from "../Common/Four";
import { Second } from "../Common/Second";
import { Six } from "../Common/Six";
import { Third } from "../Common/Third";
import php from "./../../images/pages/php.webp";
import phpdevelopment from "./../../images/pages/php_development.webp";
import php1 from "./../../images/php.webp";
import "./../../css/style.css";
import "./../../css/inner.css";
import "./../../css/plugins.css";
import useScript from "./../useScript";
import { Backbutton } from "../Common/Backbutton";
export const CustomPHPWebDevelopment = () => {
  useScript("./assets/js/scripts.js");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showBtn, setShowBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);
  return (
    <>
      {showBtn && <Backbutton />}
      <div id="page" data-barba="container">
        <div id="content" className="page-content">
          <First
            head1="Custom PHP"
            head2="web development."
            para="CMS has its limitations, making custom PHP development relevant and even more effective in certain website or web application situations. The advantages of PHP web solutions for the client's web development requirements cover the full spectrum of their businesses in London, UK. As a leading PHP web development company in London, UK, Tiger IT Solutions focuses on building custom web development solutions for its esteemed clients using PHP."
            img={php}
          />
          <Second
            secondheading1="Whether you need a dynamic or interactive website"
            secondheading2="We can offer custom PHP solutions to take it up a notch"
            img={phpdevelopment}
          />
          <Third
            head4="As a PHP developer team at Tiger IT Solutions, we can develop cutting-edge web development solutions to boost your company's profit margins in London, UK. Using PHP, we can generate small and large web applications to fit your company's needs in London, UK. We offer a wide range of custom PHP development services, including CodeIgniter and Laravel, to ensure responsive and reliable websites."
            p1="Over the years, we have gained a comprehensive understanding of London, UK's industries. Our London, UK an brands and companies all have unique propositions they want to convey. Therefore, since our inception, we have focused on custom PHP web development, delivering tailor-made solutions to manufacturers, healthcare providers, real estate developers, utilities, logistics companies, and many others."
            p2="With our custom PHP web development services in London, UK, we can meet the diverse requirements of enterprises in highly competitive environments. We deliver flexible, scalable, and reliable PHP web solutions built on the latest technology at affordable rates. Our expert team of custom PHP developers in London, UK can handle web and web app projects of any size. The focus of our business is to make high-performance PHP websites that simplify processes, streamline complexities, and add value to businesses."
            linkname="Start A Project Now"
          />

          <Four img={php1} />

          <Five
            h1="Are you looking for a custom PHP website development company in London, UK? We develop stunning websites that your business needs."
            p1="Tiger IT Solutions has shifted its stance from a simple web portal to a complex enterprise application. PHP developers in London, UK are not only skilled in traditional backend web development but also capable of developing cutting-edge web applications to deliver superb user experiences. Your business requirements can be addressed to the entire degree by PHP empowering and practical functions."
            p2="We don't charge more than you should pay on a custom PHP development project because we operate in a market like London, UK, where most web development agencies and independent developers offer competitive pricing. Depending on the unique needs of your business, the cost will vary. As a full-stack PHP web development company in London, UK, Tiger IT Solutions is flexible enough to guarantee a successful, competent website at a cost-effective budget."
            p3="To deliver premium PHP web development services in London, UK, we maintain a team of web designers, web developers, PHP programmers, Laravel developers, Codeigniter developers and other technical professionals. In addition to creating custom landing pages, our developers can write custom PHP functions to capture more leads and make product highlights more engaging. We'd love to hear from you today about your next custom PHP project in London, UK."
            linkname="Start A Project Now"
          />
          <Six link1="Start a Project" link2="Request for a Quotation" />
        </div>
      </div>
    </>
  );
};
