import React, { useEffect, useState } from "react";
import { First } from "../Common/First";
import { Second } from "../Common/Second";
import { Third } from "../Common/Third";
import { Four } from "../Common/Four";
import { Five } from "../Common/Five";
import { Six } from "../Common/Six";
import emailmarketing1 from "./../../images/email-marketing1.webp";
import emailmarketing2 from "./../../images/email-marketing2.webp";
import emailmarketing3 from "./../../images/email-marketing3.webp";
import "./../../css/style.css";
import "./../../css/inner.css";
import "./../../css/plugins.css";
import useScript from "./../useScript";
import { Backbutton } from "../Common/Backbutton";
export const EmailMarketing = () => {
  useScript("./assets/js/scripts.js");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showBtn, setShowBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);
  return (
    <>
      {showBtn && <Backbutton />}
      <div id="page" data-barba="container">
        <div id="content" className="page-content">
          <First
            head1="Email"
            head2="Marketing"
            para="TIGERS IT SOLUTIONS, Email Marketing Service makes it possible to engage with your customers and build your business. Hire us right away!."
            img={emailmarketing1}
          />
          <Second
            secondheading1="A good Website not only a benefit to business"
            secondheading2="but also helps your company grow even faster"
            img={emailmarketing2}
          />
          <Third
            head4="Business owners in London, UK must understand the importance of online marketing to flourish. No matter what industry a business is in, its online presence with a good website is paramount to its success. Besides, London, UK's economy has become highly competitive and business-friendly, so you need to hire a web development company in London, UK with professional developers and designers in tune with the latest trends."
            p1="Tiger IT Solutions, with an expert team of professional web developers and designers, is a leading website design and development company based in London, UK that creates unique websites for businesses across London, UK. Our goal is to create an award-winning website for your company that impresses customers. Being a one-stop and full-service web design and development agency in London, UK, we provide an end-to-end experience to our clients."
            p2="You cannot ignore the importance of your website for your business, whether you want to increase online sales, generate qualified leads, or drive in-store traffic in London, UK. Moreover, your business could achieve those goals with the help of an attractive, fast, and easily navigable website. So, check out how our web development company can increase your site's revenue with our award-winning services."
            linkname="Start A Project Now"
          />
          <Four img={emailmarketing3} />
          <Five
            h1="Are you looking for web development company in London, UK? We develop engaging website that your business needs."
            p1="Choosing a web development company can be a difficult decision as there are plenty of options. It is essential to find a company with an experienced team of web developers and designers who use a variety of programming languages and development platforms. As well as designers, they will have a team to create a visually appealing and unique website."
            p2="Our expert designers and developers at Tiger IT Solutions have helped countless businesses in London, UK realize their digital presence for over 12 years. As a web design and development company in London, UK, Tiger IT Solutions takes pride in delivering companies with websites that have real value. In addition to learning about your business, our skilled designers and developers will apply their expertise to produce the most satisfactory solution for your specific requirements."
            p3="Companies from all platforms in London, UK can benefit from our digital services by reaching a wider audience and delivering tangible returns. Whether you have a start-up or a large company in London, UK, we are ready to assist you! Our web design and development company would love to hear from you if you're considering partnering with us for your next project. Feel free to reach out to us today!"
            linkname="Start A Project Now"
          />
          <Six link1="Start a Project" link2="Request for a Quotation" />
        </div>
      </div>
    </>
  );
};
