import React from "react";

export const Second = (props) =>{
    return(
        <div className="wrapper-full scroll_text_con">
        <img className="second_image" alt="Web development agency in London, UK"
            src={props.img}/>
        <span className="a-empty-space" style={{height: "70px"}}></span>
        <div className="c-col-12 indexing">
            <div className="scrollable-text">
                <h1 className="big-title">{props.secondheading1}
                </h1>
            </div>
            <div className="scrollable-text-1">
                <h1 className="big-title">{props.secondheading2}
                </h1>
            </div>
        </div>
    </div>

    );
}