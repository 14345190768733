import React, { useEffect, useState } from "react";
import { First } from "../Common/First";
import { Five } from "../Common/Five";
import { Four } from "../Common/Four";
import { Second } from "../Common/Second";
import { Six } from "../Common/Six";
import { Third } from "../Common/Third";
import awardwining from "./../../images/pages/award_wining.webp";
import awardwiningwebsite from "./../../images/pages/award_wining_website.webp";
import awardwiningagency from "./../../images/award_wining_agency.webp";
import "./../../css/style.css";
import "./../../css/inner.css";
import "./../../css/plugins.css";
import useScript from "./../useScript";
import { Backbutton } from "../Common/Backbutton";
export const AwardWinningWebsiteDesign = () => {
  useScript("./assets/js/scripts.js");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showBtn, setShowBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);
  return (
    <>
      {showBtn && <Backbutton />}
      <div id="page" data-barba="container">
        <div id="content" className="page-content">
          <First
            head1="Award winning"
            head2="website design."
            para="An award winning website transcends what we call typical UX to bring in something for users that they cannot match with anything usual. If your London, UK-based business already has a website with a good UI, its design needs to be even greater to become an award winning masterpiece. An experienced website design agency in London, UK can be of help."
            img={awardwining}
          />

          <Second
            secondheading1="An award winning website offers a rapid-fire presentation"
            secondheading2="That captures and holds visitors' attention to your brand"
            img={awardwiningwebsite}
          />

          <Third
            head4="An eye-catching and responsive design enriched with interactive UI elements should be enough to evoke positive user experience. But an award winning website does more than that by pulling an emotional impact off the users and instilling credibility about your company in their minds."
            p1="Award winning websites aren't only about the richness and a stunning display of beauty in a digital form. The design of a website should wow users from the moment they land on it. It has to be so intuitive that they instantly know how to perform their intended actions. Even after they leave the site, it stays in their mind as a living entity. That's how an award winning site should be. Our website design portfolio includes one-of-a-kind examples to show how our agency stands out among the fierce competition in London, UK."
            p2="Everything we've told so far conforms to the convention of a regular website for the London, UK audience. But our website design and development agency in London, UK approaches differently as we know where a regular website differs from an award winning one. You might have heard some designers or agencies talking about parallax design, scrolling effects, text animations, fade transitions, animated elements, and all that. These features are useful but not enough for a website to win an award. An award-winning website not only draws visitors' attention but also prompts their actions when needed, leaving a memorable impression of the brand it's highlighting. Designing and developing such a website isn't a gig for an average web design and development agency in London, UK."
            linkname="Start A Project Now"
          />

          <Four img={awardwiningagency} />

          <Five
            h1="Are you looking for a award winning website design agency in London, UK? We design excellent websites that your business needs."
            p1="Our London, UK-based website design and development team consists of people with diverse skills, but it still is the creativity and sharp sense of UX of our web designers and developers which enable us to stand up to the claim. Some web designers and developers in London, UK may insist on your choosing a bold or minimalistic approach just because they know their way around either of these approaches. Without a deep understanding of the client's goal and vision, our agency doesn't only emphasize any particular web design trend because it is popular."
            p2="Since our only goal is to design an award winning website for your company or agency, we try to find the most effective and appropriate way to accomplish that. Having recognition from one of the most prestigious competition authorities named Awwwards, our agency has demonstrated its ability to craft innovative web design and contribute to the success of more than hundreds of small businesses and several large corporations in London, UK. This experience has enriched our website designers and developers in London, UK, leading each of them to the next level of excellence in web design and development for businesses."
            p3="Not all companies in London, UK are looking to win an award for their work, but your online presence established through a website having the qualities of an award winning design certainly helps you big time. If you are looking for an award winning website and not trying to win an award, you must be working to win the heart of your audience. That's pretty much the same. Many of our clients in London, UK have benefited from our talent to combine the aesthetics and business intelligence to create something that wins awards, not physically but in a more profitable and sustainable way."
            linkname="Start A Project Now"
          />
          <Six link1="Start a Project" link2="Request for a Quotation" />
        </div>
      </div>
    </>
  );
};
