import React, { useEffect, useState } from "react";
import { First } from "../Common/First";
import { Five } from "../Common/Five";
import { Four } from "../Common/Four";
import { Second } from "../Common/Second";
import { Six } from "../Common/Six";
import { Third } from "../Common/Third";
import corporatedesignagency from "./../../images/pages/corporate_design_agency.webp";
import corporatewebdesignmin from "./../../images/pages/corporate_web_design-min.webp";
import corporate from "./../../images/corporate.webp";
import "./../../css/style.css";
import "./../../css/inner.css";
import "./../../css/plugins.css";
import useScript from "./../useScript";
import { Backbutton } from "../Common/Backbutton";
export const CorporateWebsiteDesign = () => {
  useScript("./assets/js/scripts.js");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showBtn, setShowBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);
  return (
    <>
      {showBtn && <Backbutton />}
      <div id="page" data-barba="container">
        <div id="content" className="page-content">
          <First
            head1="Corporate"
            head2="website design."
            para="There are over half a million of corporate business entities in London, UK, all of which need to build their presence in their respective industries and establish a relationship with stakeholders and clients. How is that possible without a well-designed, responsive website? A corporate video might serve the purpose, but you still want to build credibility with your audience, which is why you need a corporate website in London, UK."
            img={corporatedesignagency}
          />
          <Second
            secondheading1="The window of a corporate company is its website"
            secondheading2="We can help you keep it welcoming and exciting"
            img={corporatewebdesignmin}
          />
          <Third
            head4="Finance and insurance, manufacturing, retail trade and wholesale, real estate, administrative support and professional services are the top industries in London, UK's corporate sector. Other notable industries include food and beverages, construction, transportation and logistics, information and communications."
            p1="Being a website design and development agency in London, UK with nearly a decade of experience in corporate web design, we've designed corporate websites for several companies in all of these industries in London, UK. A clear understanding of how a corporate company in London, UK works enables our designers and developers to meet clients' expectations in terms of long-term value and numbers. Not only do we consider the principles a corporate company stands for, we also translate them into an impactful design."
            p2="So many years in the business of web design and development helped us identify the pain points that keep hurting the bottom line of many corporate agencies in London, UK. Some of the goals of our London, UK-based web design agency are to ensure an accurate representation of corporate culture, help clear any confusion and barriers to communication, make data accessible, and improve the image of the agency, all through an eye-catching design. So, we can assist whether you merely want to improve your brand recognition or supplement your sales channel or both in London, UK."
            linkname="Start A Project Now"
          />

          <Four img={corporate} />

          <Five
            h1="Are you looking for a corporate website design agency in London, UK? We design engaging corporate websites that your business needs."
            p1="The design of a corporate website must comply with the branding principles of the company. The website should facilitate the company's primary goal by focusing on the very part that informs the audience of its activities and values. Since simple navigation and easy information architecture result in great usability, we design every part of your corporate website to keep it intuitive and simple. At the same time, designers and developers at our agency apply their skills to create a fresh and telling user interface."
            p2="Colours play a significant role in our corporate web design process in which we follow the client's demand as to the choice of the key visual elements that constitute the identity of the brand in London, UK. Once chosen, the colours are used in the logo, web UI, promotional material, and landing pages. You may want to have a primary colour in your brand palette and a few secondary colours (s). Our design agency in London, UK presents you a lot of possibilities as far as you are concerned about picking the right colour scheme for your website."
            p3="You may have heard that corporate websites cannot be better than bland or plain. Unfortunately, some website design agencies in London, UK have led clients to believe so. Our web designers and developers have managed to look beyond this so-called convention and demonstrated that even corporate websites can have great design too. We may sound way too optimistic, but we are eager to show you the ingenuity and creativity that lead to cutting-edge design. We have an impressive corporate web design portfolio that backs our claim in London, UK."
            linkname="Start A Project Now"
          />
          <Six link1="Start a Project" link2="Request for a Quotation" />
        </div>
      </div>
    </>
  );
};
