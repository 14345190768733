import React from "react";
import home from "./../../images/home.webp";
export const HomeFourSec = () => {
  return (
    <div className="section">
      <div className="wrapper no-margin indexing">
        <div className="c-col-3 hide_mobile"></div>
        <div className="c-col-9">
          <div className="text-wrapper">
            <h5
              className="has-anim"
              data-stagger="0.1"
              data-duration="1.5"
              data-delay=".1"
              data-animation="linesUp"
            >
              Our company in London, UK has always considered the fact that each
              client has a unique business proposition to offer their audience
              in London, UK. This led us to put focus on both website design and
              development separately but in a coordinated manner. As much as our
              developers and designers strive for excellence in website design,
              our developers and designers are consistently pursuing to develop
              web applications that meet the varying demands of clients'
              businesses in London, UK. Every successful project is the result
              of the combined effort from both groups of professionals in
              London, UK.
            </h5>
          </div>
        </div>
      </div>
      <div className="wrapper no-margin indexing">
        <div className="c-col-3 hide_mobile"></div>
        <div className="c-col-9">
          <span
            data-color="hsla(0,0%,100%,.2)"
            data-anim="true"
            className="alioth-seperator"
          ></span>
        </div>
        <div className="c-col-3 hide_mobile"></div>
        <div className="c-col-4">
          <div className="text-wrapper">
            <h2
              className="has-anim rotate_arrow"
              data-stagger="0.05"
              data-duration="1.25"
              data-delay="1"
              data-animation="linesUp"
            >
              Website Design <i className="icofont-arrow-right"></i>
            </h2>
          </div>
        </div>
        <div className="c-col-5">
          <p
            style={{ color: "rgba(255, 255, 255, .7)" }}
            className="has-anim"
            data-stagger="0.05"
            data-duration="1.25"
            data-delay="1"
            data-animation="linesUp"
          >
            Our web development and design services include creative and
            responsive website design for corporate companies and ecommerce
            businesses in London, UK. In doing so, we build custom WordPress
            themes or design a website using themes preferred by the clients.
            Besides WordPress website design and development, we maintain a
            stellar track record of success in designing creative, interactive
            and award winning websites for clients in London, UK. Ecommerce
            website design is one of the primary areas where our agency caters
            to the needs of every type of business, from a startup company to
            the famous ecommerce brands in London, UK.
          </p>
        </div>
      </div>
      <div className="wrapper no-margin indexing">
        <div className="c-col-3 hide_mobile"></div>
        <div className="c-col-9">
          <span
            data-color="hsla(0,0%,100%,.2)"
            data-anim="true"
            className="alioth-seperator"
          ></span>
        </div>
        <div className="c-col-3 hide_mobile"></div>
        <div className="c-col-4">
          <div className="text-wrapper">
            <h2
              className="has-anim rotate_arrow"
              data-stagger="0.05"
              data-duration="1.25"
              data-delay="1"
              data-animation="linesUp"
            >
              Web Development
              {/* <i className="icofont-arrow-right"></i> */}
              <i className="icofont-arrow-right"></i>
            </h2>
          </div>
        </div>
        <div className="c-col-5">
          <p
            style={{ color: "rgba(255, 255, 255, .7)" }}
            className="has-anim"
            data-stagger="0.05"
            data-duration="1.25"
            data-delay="1"
            data-animation="linesUp"
          >
            Our web development and designing services include website design
            and development based on CMS or no CMS, custom web application
            development based on PHP and Laravel, WordPress plugin customisation
            and development, CRM development for ecommerce and corporate
            companies, and many more. Our web designers and developers are
            capable of using and customising various JavaScript libraries and
            frameworks to create stunning websites featuring rich animation and
            interactive effects. Our agency has access to the latest
            technologies as well as the older versions to help any company with
            custom website design and development in London, UK.
          </p>
        </div>
      </div>

      <div className="wrapper"></div>

      <div className="wrapper-full no-gap no-margin">
        <div
          className="c-col-10 no-gap has-parallax"
          data-parallax-strength="0.15"
          data-parallax-direction="down"
        >
          <div
            data-parallax-type="directional"
            data-parallax="true"
            className="single-image"
          >
            <img
              className="initial_display"
              alt="Web Design Company in London, UK"
              src={home}
            />
          </div>
        </div>
        <div className="c-col-2 hide_mobile"></div>
      </div>
    </div>
  );
};
