import React, { useEffect, useState } from "react";
import ukneedswebsitethumb from "./../../images/blog_img/UK_needs_website_thumb.webp";
import responsivewebsitethumb from "./../../images/blog_img/responsive_website_thumb.webp";
import ecommercethumb from "./../../images/blog_img/ecommerce_thumb.webp";
import webdesignthumb from "./../../images/blog_img/web_design_thumb.webp";
import { Link } from "react-router-dom";
import "./../../css/style.css";
import "./../../css/inner.css";
import "./../../css/plugins.css";
import useScript from "./../useScript";
import { Backbutton } from "../Common/Backbutton";
export const Blog = () => {
  useScript("./assets/js/scripts.js");
  const pagerefresh = () => {
    window.location.pagerefresh();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showBtn, setShowBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);
  return (
    <>
      {showBtn && <Backbutton />}
      <div id="page" data-barba="container">
        <div id="content" className="page-content blog_page indexing">
          <div className="section">
            <div className="wrapper-small">
              <div className="c-col-2 hide_mobile"></div>
              <div className="c-col-10">
                <div className="alioth-blog blog-list">
                  <div className="post alioth-post sticky">
                    <div className="post-image">
                      <img alt="Responsive Website" src={ukneedswebsitethumb} />
                    </div>
                    <Link to="/blog_responsive_website" onClick={pagerefresh}>
                      <div className="post-date">19 June, 2022</div>
                      <div className="post-title">
                        <h4>
                          What Is a Responsive Website and Why It's Important?
                        </h4>
                      </div>
                      <h5 className="post-summary">
                        A responsive website is the one that responds to users'
                        behaviour and interactions based on the various screen
                        sizes, orientations, and platforms. As people in UK are
                        getting interested in instant gratification from
                        anything they do online, their attention spans continue
                        to shrink where a responsive website can appeal to their
                        behaviour by improving readability and enhancing
                        interactions, thereby increasing the duration of their
                        stay on a website.
                      </h5>
                    </Link>
                  </div>

                  <div className="post alioth-post sticky">
                    <div className="post-image">
                      <img
                        alt="Responsive Website"
                        src={responsivewebsitethumb}
                      />
                    </div>
                    <Link to="/blog_uk_needs_website" onClick={pagerefresh}>
                      <div className="post-date">19 June, 2022</div>
                      <div className="post-title">
                        <h4>
                          5 Reasons Why Your Business in UK Needs a Website
                        </h4>
                      </div>
                      <h5 className="post-summary">
                        Several misconceptions lead business owners in UK and
                        everywhere across the world to believe they don't need a
                        website for their company. Some think their business in
                        UK is just not large enough to need a website while
                        others seem to consider a website merely an online
                        brochure. Some entrepreneurs in UK even believe that
                        websites and their maintenance are too expensive and
                        difficult for them to manage.
                      </h5>
                    </Link>
                  </div>
                  <div className="post alioth-post sticky">
                    <div className="post-image">
                      <img alt="Responsive Website" src={ecommercethumb} />
                    </div>
                    <Link to="/blog_ecommerce" onClick={pagerefresh}>
                      <div className="post-date">19 June, 2022</div>
                      <div className="post-title">
                        <h4>
                          5 Factors to Consider When Choosing an Ecommerce
                          Platform in UK
                        </h4>
                      </div>
                      <h5 className="post-summary">
                        The choice of an ecommerce platform in UK comes prior to
                        the design & development of an ecommerce website. In
                        fact, the platform you choose dictates the overall
                        capability and strength of your website that will
                        represent your business in UK. There are dozens of
                        platforms, each claiming to be good at what they're
                        built to do. How do you choose the right one for your
                        UK-based ecommerce business?
                      </h5>
                    </Link>
                  </div>

                  <div className="post alioth-post sticky">
                    <div className="post-image">
                      <img alt="Responsive Website" src={webdesignthumb} />
                    </div>
                    <Link
                      to="/blog_web_design_development"
                      onClick={pagerefresh}
                    >
                      <div className="post-date">19 June, 2022</div>
                      <div className="post-title">
                        <h4>
                          5 Factors to Consider Before Hiring a Web Design and
                          Development Company in UK
                        </h4>
                      </div>
                      <h5 className="post-summary">
                        The choice of an ecommerce platform in UK comes prior to
                        the design & development of an ecommerce website. In
                        fact, the platform you choose dictates the overall
                        capability and strength of your website that will
                        represent your business in UK. There are dozens of
                        platforms, each claiming to be good at what they're
                        built to do. How do you choose the right one for your
                        UK-based ecommerce business?
                      </h5>
                    </Link>
                  </div>
                  <div className="post-images"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
