import { Home } from "./component/Home/Home";
import { Route, Routes, useLocation } from "react-router-dom";
import React from "react";
import { Aboutus } from "./component/Aboutus";
import { EcommersePSG } from "./component/EcommersePSG";
import { Footer } from "./component/Footer";
import { Header } from "./component/Header";
import { PortFolio } from "./component/PortFolio";
import { WebsiteMentainance } from "./component/WebsiteMentainance";
import { WebsiteRevamp } from "./component/WebsiteRevamp";
import { AwardWinningWebDevelopment } from "./component/WebDevelopment/AwardWinningWebDevelopment";
import { CorporateWebDevelopment } from "./component/WebDevelopment/CorporateWebDevelopment";
import { CustomPHPWebDevelopment } from "./component/WebDevelopment/CustomPHPWebDevelopment";
import { EcommerceWebDevelopment } from "./component/WebDevelopment/EcommerceWebDevelopment";
import { ResponsiveWebDevelopment } from "./component/WebDevelopment/ResponsiveWebDevelopment";
import { WebDevelopment } from "./component/WebDevelopment/WebDevelopment";
import { WordPressWebDevelopment } from "./component/WebDevelopment/WordPressWebDevelopment";
import { AwardWinningWebsiteDesign } from "./component/WebsiteDesign/AwardWinningWebsiteDesign";
import { CorporateWebsiteDesign } from "./component/WebsiteDesign/CorporateWebsiteDesign";
import { CreativeWebDesign } from "./component/WebsiteDesign/CreativeWebDesign";
import { InteractiveWebDesign } from "./component/WebsiteDesign/InteractiveWebDesign";
import { ResponsiveWebsiteDesign } from "./component/WebsiteDesign/ResponsiveWebsiteDesign";
import { WebsiteDesign } from "./component/WebsiteDesign/WebsiteDesign";
import { WordPressWebsiteDesign } from "./component/WebsiteDesign/WordPressWebsiteDesign";
import { AndroidApplicationDevelopment } from "./component/MobileDevelopment/AndroidApplicationDevelopment";
import { IOSApplicationDevelopment } from "./component/MobileDevelopment/IOSApplicationDevelopment";
import { ReactNativeAppDevelopment } from "./component/MobileDevelopment/ReactNativeAppDevelopment";
import { ContentMarketing } from "./component/DigitalMarketing/ContentMarketing";
import { EmailMarketing } from "./component/DigitalMarketing/EmailMarketing";
import { Ppc } from "./component/DigitalMarketing/Ppc";
import { SemSmm } from "./component/DigitalMarketing/SemSmm";
import { SeoSmo } from "./component/DigitalMarketing/SeoSmo";
import { ContactUs } from "./component/ContactUs";
import { Blog } from "./component/Blog/Blog";
import { CreativeWebDevelopment } from "./component/WebDevelopment/CreativeWebDevelopment";
import { EcommerseWebsiteDesign } from "./component/WebsiteDesign/EcommerseWebsiteDesign";
import { Messagesend } from "./component/Messagesent";
import { BlogEcommerce } from "./component/Blog/BlogEcommerce";
import { BlogResponsiveWebsite } from "./component/Blog/BlogResponsiveWebsite";
import { BlogUkNeedsWebsite } from "./component/Blog/BlogUkNeedsWebsite";
import { BlogWebDesignDevelopment } from "./component/Blog/BlogWebDesignDevelopment";
import { TermsandConditions } from "./component/Termsandconditions/Termsandconditions";
import { Privacypolicy } from "./component/PrivacyPolicy/Privacypolicy";
import { Cursor } from "./component/Common/Cursor";
import { Loader } from "./component/Common/Loader";
function App() {
  const location = useLocation();
  return (
    <>
      <Cursor />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<Aboutus />} />
        <Route path="/ecommerse-psg" element={<EcommersePSG />} />
        <Route path="/website-mentainance" element={<WebsiteMentainance />} />
        <Route path="/website-revamp" element={<WebsiteRevamp />} />
        <Route path="/portfolio" element={<PortFolio />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/messagesent" element={<Messagesend />} />
        {/* Web development Routing */}
        <Route
          path="/award-winning-website-development"
          element={<AwardWinningWebDevelopment />}
        />
        <Route
          path="/corporate-web-development"
          element={<CorporateWebDevelopment />}
        />
        <Route
          path="/creative-web-development"
          element={<CreativeWebDevelopment />}
        />
        <Route
          path="/customphp-web-development"
          element={<CustomPHPWebDevelopment />}
        />
        <Route
          path="/ecommerce-web-development"
          element={<EcommerceWebDevelopment />}
        />
        <Route
          path="/responsive-web-development"
          element={<ResponsiveWebDevelopment />}
        />
        <Route path="/web-development" element={<WebDevelopment />} />
        <Route
          path="/wordpress-web-development"
          element={<WordPressWebDevelopment />}
        />
        {/* Web development routing close */}
        {/* Web design Routing */}
        <Route
          path="/award-winning-website-design"
          element={<AwardWinningWebsiteDesign />}
        />
        <Route
          path="/corporate-web-design"
          element={<CorporateWebsiteDesign />}
        />
        <Route path="/creative-web-design" element={<CreativeWebDesign />} />
        <Route
          path="/ecommerce-web-design"
          element={<EcommerseWebsiteDesign />}
        />
        <Route
          path="/interactive-web-design"
          element={<InteractiveWebDesign />}
        />
        <Route
          path="/responsive-web-design"
          element={<ResponsiveWebsiteDesign />}
        />
        <Route path="/web-design" element={<WebsiteDesign />} />
        <Route
          path="/wordpress-web-design"
          element={<WordPressWebsiteDesign />}
        />
        {/* Web development Routing */}
        {/* Android development Routing */}
        <Route
          path="/android-application-development"
          element={<AndroidApplicationDevelopment />}
        />
        <Route
          path="/ios-application-development"
          element={<IOSApplicationDevelopment />}
        />
        <Route
          path="/react-native-app-development"
          element={<ReactNativeAppDevelopment />}
        />
        {/* Android development Routing */}
        {/* Digital Marketing Routing */}
        <Route path="/content-marketing" element={<ContentMarketing />} />
        <Route path="/email-marketing" element={<EmailMarketing />} />
        <Route path="/ppc" element={<Ppc />} />
        <Route path="/sem-smm" element={<SemSmm />} />
        <Route path="/seo-smo" element={<SeoSmo />} />
        {/* Digital Marketing Routing */}
        {/* Blog */}
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog_ecommerce" element={<BlogEcommerce />} />
        <Route
          path="/blog_responsive_website"
          element={<BlogResponsiveWebsite />}
        />
        <Route path="/blog_uk_needs_website" element={<BlogUkNeedsWebsite />} />
        <Route
          path="/blog_web_design_development"
          element={<BlogWebDesignDevelopment />}
        />
        <Route
          path="/termandcondition"
          element={<TermsandConditions />}
        />
        <Route
          path="/privacypolicy"
          element={<Privacypolicy />}
        />

        {/* Blog end */}
      </Routes>

      {!location.pathname.startsWith("/portfolio") && <Footer />}
      <Loader />
    </>
  );
}
export default App;
