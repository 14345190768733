import React from "react";
export const Four = (props) => {
    return (
        <div className="wrapper-full no-gap no-margin">
            <div className="c-col-10 no-gap " data-parallax-strength="0.15" data-parallax-direction="down">
                <div data-parallax-type="directional" data-parallax="true" className="single-image">
                    <img className="initial_display" alt="Web Design Company in London, UK" src={props.img} />
                </div>
            </div>
            <div className="c-col-2"></div>
        </div>
    );
}