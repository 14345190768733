import React from "react";
import { Link } from "react-router-dom";
export const HomeSevenSec = () => {
  const pagerefresh = () => {
    window.location.pagerefresh();
  };

  return (
    <div className="section">
      <div className="wrapper-full no-margin indexing">
        <div className="c-col-12 no-gap no-margin">
          <div className="alioth-page-nav">
            <Link to="/contact-us" onClick={pagerefresh}>
              <div className="page-title">Start a Project</div>
              <div className="page-sub-title">Request for a Quotation</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
