import React, { useEffect, useState } from "react";
import { First } from "../Common/First";
import { Five } from "../Common/Five";
import { Four } from "../Common/Four";
import { Second } from "../Common/Second";
import { Six } from "../Common/Six";
import { Third } from "../Common/Third";
import "./../../css/style.css";
import "./../../css/inner.css";
import "./../../css/plugins.css";
import useScript from "./../useScript";
import responsive from "./../../images/pages/responsive.webp";
import responsivewebdevelopment from "./../../images/pages/responsive_web_development.webp";
import responsivewebdevelopmentcompany from "./../../images/responsive_web_development_company.webp";
import { Backbutton } from "../Common/Backbutton";
export const ResponsiveWebDevelopment = () => {
  useScript("./assets/js/scripts.js");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showBtn, setShowBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);
  return (
    <>
      {showBtn && <Backbutton />}
      <div id="page" data-barba="container">
        <div id="content" className="page-content">
          <First
            head1="Responsive"
            head2="web development."
            para="As a reputable web design and development company in London, UK, we recognise the ever-growing needs for websites that work perfectly on any device. With Google's priority on mobile-friendly websites, businesses also look beyond the traditional concept behind having a website. Our expert designers and developers adopt the best responsive web development (RWD) approaches to allow visitors to quickly find information and enable companies to generate more traffic and conversion in London, UK."
            img={responsive}
          />
          <Second
            secondheading1="About 90% of people in London, UK use a smartphone"
            secondheading2="You can't impress them without a responsive website"
            img={responsivewebdevelopment}
          />
          <Third
            head4="Our web design and development team consist of some highly experienced web designers and developers who genuinely believe in improving user experience, and a responsive website is the most important part of the process. Responsive web design doesn't have to be expensive in London, UK. All it takes is the right skills and the amount of experience, enough to understand how different devices and browsers render a website."
            p1="Our web designers and developers in London, UK believe a responsive website or web page means more than simply making it mobile-friendly. Since almost half of the global traffic originates from mobile devices, we strive to make sure your customers in London, UK feel like the website they're on is an experience they can cherish with and enjoy."
            p2="We have successfully created accessible, responsive web designs in London, UK for a wide array of platforms and corporate website designs, using various devices like laptops, smartphones, tablets, and computers. Providing you with the fluid flexibility to dynamically adapt your website to different browsers and device viewports without changing the content and layout, our responsive website designing company in London, UK provides you with a more realistic view."
            linkname="Start A Project Now"
          />

          <Four img={responsivewebdevelopmentcompany} />

          <Five
            h1="Are you looking for a responsive web development company in London, UK? We develop responsive websites that your business needs."
            p1="The business landscape in London, UK is constantly changing, as we know as web designers and developers in London, UK. Therefore, we approach all aspects of design and development in such a way that we remain competitive. Nowadays, search engines prefer a responsive website by taking this aspect into account for rankings."
            p2="Fortunately, we have a team of expert web designers and developers who understand how to craft a responsive website that boosts your company's credibility and consolidates its position in a business environment as dynamic as London, UK's. To understand how their customers are using mobile devices, we suggest our clients get mobile-optimized websites. We can offer you a website that supports your growth strategy in London, UK through the help of our web designers and developers."
            p3="Like most other businesses in London, UK, you might have a website, but it may be outdated and not user-friendly. Our web developers and designers at Tiger IT Solutions know how critical it is to update an old website to boost traffic, generate more leads, and add functionality that improves the user experience. Our expert web design and development team in London, UK implements a precise web design process and ensures every company in London, UK gets a responsive website that loads properly on any device and browser. Therefore, if you need a mobile-ready version to stay competitive in the market, we can just be the right responsive web development company in London, UK."
            linkname="Start A Project Now"
          />
          <Six link1="Start a Project" link2="Request for a Quotation" />
        </div>
      </div>
    </>
  );
};
