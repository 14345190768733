import React, { useEffect, useState } from "react";
import { First } from "./Common/First";
import { Five } from "./Common/Five";
import { Four } from "./Common/Four";
import { Second } from "./Common/Second";
import { Third } from "./Common/Third";
import { Six } from "./Common/Six";
import useScript from "./useScript";
import websitemaintenance from "./../images/pages/website_maintenance .webp";
import websitemaintenanceservice from "./../images/pages/website_maintenance_service.webp";
import websitemaintenanceserviceuk from "./../images/website_maintenance_service_UK.webp";
import "./../css/style.css";
import "./../css/inner.css";
import { Backbutton } from "./Common/Backbutton";
export const WebsiteMentainance = () => {
  useScript("./assets/js/scripts.js");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showBtn, setShowBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);
  return (
    <>
      {showBtn && <Backbutton />}
      <div id="page" data-barba="container">
        <div id="content" className="page-content">
          <First
            head1="Website"
            head2="Maintenance."
            para="Every second counts as the user lands on your website. You need to keep detecting issues that might be preventing users from enjoying their stay on your website. Thus, website maintenance is essential, and the web design agency in London, UK doing the job for your company can play a crucial role."
            img={websitemaintenance}
          />
          <Second
            secondheading1="Keep your website secured and updated"
            secondheading2="With maintenance service we provide "
            img={websitemaintenanceservice}
          />
          <Third
            head4="Every business in London, UK or anywhere else wants to grow and thrive, regardless of the size of their customer base. More than half of the companies in London, UK suffer from poor user experience resulting from a website that doesn't load as fast as expected or offer great usability. Knowingly or unknowingly, you may just be one of those entrepreneurs eager enough to address the issue but don't know which agency in London, UK to contact."
            p1="A website serves several purposes, from presenting your company before the crowd that would have no way to know about your business otherwise, to helping you benefit from an increasing interest in users relying on the internet for their day-to-day needs. But that website cannot really help much if it fails to perform what it's designed to. Website maintenance service is one of the key initiatives to ensure that your company website is up and running to satisfy the demands of your audience in London, UK."
            p2=" Our portfolio includes virtually all kinds of legitimate companies operating within the
                    business sector in London, UK. Our talented web design and development professionals enjoy
                    working on diverse projects that often come with some very specific or highly challenging
                    requirements. Whether yours is a small or medium company, or you're representing a large
                    corporation that needs a stunning look and an efficient functional side, we've got the
                    numbers to impress you. But we take a different approach. We try to go deeper into your
                    business objectives and help you set the right goals for your company as to establishing an
                    online identity and building reputation in London, UK."
            linkname="Start A Project Now"
          />
          <Four img={websitemaintenanceserviceuk} />
          <Five
            h1="Are you worried about your website or your branding?
                    Let's build something great together!"
            p1="Our website maintenance service in London, UK includes quite a number of activities with the most important ones being taking backups on a scheduled basis, checking and improving the site speed, detecting and fixing broken links and other URL issues, checking and ensuring browser compatibility, adding/ improving the visuals, and everything that is related with the site's security. Based on the needs of your company and the frequency of maintenance, we can offer attractive deals so that you can have the peace of mind knowing you're getting the best value for your money and your website is doing what it's built for."
            p2="Tiger IT Solutions aims for each client in London, UK to enjoy the best value for their money. About 90% of all our clients in London, UK have entrusted us with the maintenance of their website after we've designed at least one website for them. Many of them even have counted the maintenance service with us since the first year of our journey. This incredible rate of retention didn't come magically or overnight. Our web designers and developers in London, UK are always eager to utilise their skills for the improvement of the client's website as well as the brand."
            p3="Just about any web design and development agency in London, UK can claim to have the ability to provide these services, but you know tasks like site speed, overall improvement of UX, and optimisation of the design based on micro interactions take professionals years to master. Fortunately, the designers and developers in the team at our agency in London, UK has gathered the knowledge and skills to apply the maintenance tweaks to your website following the industry standards."
            linkname="Start A Project Now"
          />
          <Six link1="Start a Project" link2="Request for a Quotation" />
        </div>
      </div>
    </>
  );
};
