import { processRawPlugins } from "js-plugin";
import React from "react";
import { Link } from "react-router-dom";
export const Third = (props) => {
  const pagerefresh = () => {
    window.location.pagerefresh();
  };
  return (
    <div id="inner_content" className="wrapper indexing">
      <div className="c-col-4 hide_mobile"></div>
      <div className="c-col-8">
        <div className="text-wrapper">
          <h4
            className="has-anim"
            data-stagger="0.1"
            data-duration="1"
            data-delay=".5"
            data-animation="linesUp"
          >
            {props.head4}
          </h4>
          <span className="a-empty-space" style={{ height: "10px" }}></span>
          <span
            data-color="hsla(0,0%,100%,.4)"
            data-anim="true"
            className="alioth-seperator"
          ></span>
          <span className="a-empty-space" style={{ height: "30px" }}></span>
          <p
            className="has-anim"
            data-stagger="0.1"
            data-duration="1"
            data-delay="1"
            data-animation="linesUp"
            style={{ color: "hsla(0,0%,100%,.4)" }}
          >
            {props.p1}
          </p>
          <p
            className="has-anim"
            data-stagger="0.1"
            data-duration="1"
            data-delay="1.5"
            data-animation="linesUp"
            style={{ color: "hsla(0,0%,100%,.4)" }}
          >
            {props.p2}
          </p>
          <div className="a-button style_1">
            <span
              className="button-overlay"
              style={{ top: "1467px", left: "27px", height: "0%", width: "0%" }}
            ></span>
            <Link to="/contact-us" onClick={pagerefresh}>
              {props.linkname}
            </Link>
            <i className="icofont-arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
  );
};
