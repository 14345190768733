import React from "react";
export const Cursor = () => {
  return (
    <>
      {/* <div data-duration="3" className="alioth-page-loader" data-layout="dark">
        <span className="apl-background"></span>
        <div className="apl-count"></div>
      </div> */}
      <div className="alioth-page-transitions" data-layout="dark">
        <span className="apt-bg"></span>
        <div className="trans-text">Loading, please wait..</div>
      </div>
      <div
        data-dark-circle="rgba(25,27,29,.6)"
        data-dark-dot="#191b1d"
        data-light-circle="hsla(0,0%,100%,.2)"
        data-light-dot="#fff"
        id="mouseCursor"
      >
        <div id="cursor"></div>
        <div id="dot"></div>
      </div>
    </>
  );
};
